// Display instructions for getting sharc into bluetooth mode

export default function Instructions() {
  return (
    <div id="instructions">
      <div className="instructions-container">
        <div className="instructions-connect">Press and hold the MODE button on your SHARC until the status light is cyan</div>
      </div>
    </div>
  )
}