import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';


export default function ChangeModbus({ openChangeModbus, setOpenChangeModbus, rtu, registers, setRegisters, client, deviceId }) {
    const [registerStates, setRegisterStates] = useState(registers);
    const [openAddRegister, setOpenAddRegister] = useState(false);
    const [openDeleteRegister, setOpenDeleteRegister] = useState(false);
    const [selectedRegister, setSelectedRegister] = useState(false);
    const [enabled, setEnabled] = useState(rtu.enabled)
    const [baudRate, setBaudRate] = useState(rtu.setup.baud_rate)
    const [readTimeout, setReadTimeout] = useState(rtu.setup.read_timeout_chars)
    const [readInterval, setReadInterval] = useState(rtu.interval_ms)
    const [newName, setNewName] = useState('')
    const [newRegisterType, setNewRegisterType] = useState('holding-register')
    const [newEnabled, setNewEnabled] = useState(true)
    const [newID, setNewID] = useState('')
    const [newAddress, setNewAddress] = useState('')
    const [newUnits, setNewUnits] = useState('')

    function updateRegisterState(registerName, field, value) {
        setRegisterStates(prevStates => ({
            ...prevStates,
            [registerName]: {
                ...prevStates[registerName],
                [field]: value
            }
        }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        try {
            let message = new Paho.Message(JSON.stringify({
                "id": uuidv4(),
                "v": {
                    "rtu.setup.baud_rate": parseInt(baudRate),
                    "rtu.setup.read_timeout_chars": parseInt(readTimeout),
                    "rtu.enabled": enabled,
                    "rtu.registers": registerStates,
                    "rtu.interval_ms": parseInt(readInterval)
                }
            }));

            message.destinationName = `sharc/${deviceId}/cmd/cfg`
            client.send(message)
        } catch (err) {
            console.log(err)
        }
        setOpenChangeModbus(false);
    }

    return (
        <>
            {!openAddRegister && <div id="mqtt-modal" onMouseDown={(e) => setOpenChangeModbus(false)}>
                <div className="modal-container" onMouseDown={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <button className="exit hidden">X</button>
                        <div className="modal-title">Edit Modbus Configuration</div>
                        <button className="exit" onClick={() => setOpenChangeModbus(false)}>X</button>
                    </div>
                    <form className="modal-form" onSubmit={handleSubmit}>
                        <div className="gen-config-title" style={{ fontSize: 25 }}>General</div>
                        <label className="checkbox">Modbus Enabled:
                            <input
                                type='checkbox'
                                onChange={(e) => setEnabled(e.target.checked)}
                                checked={enabled}
                            />
                        </label>
                        <label className="checkbox">Baud Rate:
                            <input
                                type='text'
                                onChange={(e) => setBaudRate(e.target.value)}
                                value={baudRate}
                            />
                        </label>
                        <label className="checkbox">Read Interval:
                            <input
                                type='text'
                                onChange={(e) => setReadInterval(e.target.value)}
                                value={readInterval}
                            />
                        </label>
                        <label className="checkbox">Read Timeout Chars:
                            <input
                                type='text'
                                onChange={(e) => setReadTimeout(e.target.value)}
                                value={readTimeout}
                            />
                        </label>




                        <div className="gen-config-title" style={{ fontSize: 25 }}>Registers</div>
                        {Object.keys(registerStates).map((key) => (
                            <div key={key} className="form-container">
                                <div className='gen-config-title' style={{ fontSize: 20 }}>{key.charAt(0).toUpperCase() + key.slice(1)}</div>
                                <div className='modbus-registers-options'>
                                    <label className="checkbox">Enabled:
                                        <input
                                            type='checkbox'
                                            onChange={(e) => updateRegisterState(key, 'enabled', e.target.checked)}
                                            checked={registerStates[key].enabled}
                                        />
                                    </label>
                                </div>
                                <div className='modbus-registers-options'>
                                    <label>Slave ID:
                                        <input
                                            type='text'
                                            onChange={(e) => updateRegisterState(key, 'id', e.target.value)}
                                            value={registerStates[key].id}
                                        />
                                    </label>
                                </div>
                                <div className='modbus-registers-options'>
                                    <label>Address:
                                        <input
                                            type='text'
                                            onChange={(e) => updateRegisterState(key, 'addr', e.target.value)}
                                            value={registerStates[key].addr}
                                        />
                                    </label>
                                </div>
                                <div className='modbus-registers-options'>
                                    <label>Display Units:
                                        <input
                                            type='text'
                                            onChange={(e) => updateRegisterState(key, 'transform', `(v[0], '${e.target.value}', False)`)}
                                            value={registerStates[key].transform.split(',')[1].replaceAll('\'', '').trim()}
                                        />
                                    </label>
                                </div>
                                <button style={{ padding: '10px', marginTop: 10 }} onClick={(e) => {
                                    e.preventDefault()
                                    setOpenDeleteRegister(true)
                                    setSelectedRegister(key)

                                }}>Delete</button>

                            </div>
                        ))}
                        <div className="save"><button type="button" onClick={(e) => {
                            e.preventDefault()
                            setOpenAddRegister(true)
                        }}>Add New Register</button></div>
                        <div className='reboot-container'>
                            <div className="save"><button type="submit">Update</button></div>
                            <div className="reboot cancel"><button onClick={() => setOpenChangeModbus(false)}>Cancel</button></div>
                        </div>
                    </form>
                </div>
            </div>
            }
            {openAddRegister && <div id="mqtt-modal" onMouseDown={(e) => setOpenAddRegister(false)}>
                <div className="modal-container" onMouseDown={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <button className="exit hidden">X</button>
                        <div className="modal-title">Add Modbus Register</div>
                        <button className="exit" onClick={() => setOpenAddRegister(false)}>X</button>
                    </div>

                    <form className="modal-form" onSubmit={(e) => {
                        e.preventDefault()
                        let newRegisterAdd = {
                            [newName]: {
                                fc: newRegisterType === 'holding-register' ? 3 : 4,
                                qty: 1,
                                enabled: newEnabled,
                                id: parseInt(newID),
                                addr: parseInt(newAddress),
                                transform: `(v[0], '${newUnits}', False)`
                            }
                        }
                        setRegisterStates(prevStates => ({
                            ...prevStates,
                            ...newRegisterAdd
                        }))
                        setOpenAddRegister(false)
                    }}>
                        <div className='modbus-registers-options'>
                            <label className="checkbox">Enabled:
                                <input
                                    type='checkbox'
                                    checked={newEnabled}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setNewEnabled(e.target.checked)
                                    }}
                                />
                            </label>
                        </div>
                        <div className='modbus-registers-options'>
                            <label>Name:
                                <input
                                    type='text'
                                    value={newName}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setNewName(e.target.value)
                                    }}
                                />
                            </label>
                        </div>
                        <div className='modbus-registers-options'>
                            <label>Register Type:
                                <select value={newRegisterType} onChange={(e) => {
                                    setNewRegisterType(e.target.value)
                                }} style={{ "padding": "8px 30px 8px 30px", borderRadius: 4 }}>
                                    <option value="holding-register">Holding Register</option>
                                    <option value="input-register">Input Register</option>
                                </select>
                            </label>
                        </div>
                        <div className='modbus-registers-options'>
                            <label>ID:
                                <input
                                    type='text'
                                    value={newID}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setNewID(e.target.value)
                                    }}
                                />
                            </label>
                        </div>
                        <div className='modbus-registers-options'>
                            <label>Address:
                                <input
                                    type='text'
                                    value={newAddress}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setNewAddress(e.target.value)
                                    }}
                                />
                            </label>
                        </div>
                        <div className='modbus-registers-options'>
                            <label>Display Units:
                                <input
                                    type='text'
                                    value={newUnits}
                                    onChange={(e) => {
                                        e.preventDefault()
                                        setNewUnits(e.target.value)
                                    }}
                                />
                            </label>
                        </div>
                        <div className='reboot-container'>
                            <div className="save"><button type="submit">Update</button></div>
                            <div className="reboot cancel"><button type="button" onClick={() => setOpenAddRegister(false)}>Cancel</button></div>
                        </div>
                    </form>
                </div>
            </div>
            }
            {openDeleteRegister && <div id="mqtt-modal" onMouseDown={(e) => setOpenDeleteRegister(false)}>
                <div className="modal-container" onMouseDown={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <button className="exit hidden">X</button>
                        <div className="modal-title">Delete Modbus Register</div>
                        <button className="exit" onClick={() => setOpenDeleteRegister(false)}>X</button>
                    </div>

                    <form className="modal-form" onSubmit={(e) => {
                        e.preventDefault()
                        setRegisterStates(prevStates => {
                            const updatedState = { ...prevStates };
                            delete updatedState[selectedRegister];
                            return updatedState;
                        });
                        setSelectedRegister('')
                        setOpenDeleteRegister(false)

                    }}>
                        {/* <div></div> */}
                        <div className='gen-config-title' style={{ fontSize: 20 }}> Are you sure you want to delete {selectedRegister.charAt(0).toUpperCase() + selectedRegister.slice(1)} register?</div>
                        <div className='reboot-container'>
                            <div className="save"><button type="submit">Yes</button></div>
                            <div className="reboot cancel"><button type="button" onClick={() => setOpenDeleteRegister(false)}>Cancel</button></div>
                        </div>
                    </form>
                </div>
            </div>}
        </>
    )
}