import { LineChart, YAxis, XAxis, Line } from 'recharts'

// This is used to create a stepped chart to display the SHARC data
export function SteppedChart({ chartData }) {
  return (
    <LineChart width={1200} height={500} data={chartData}
      margin={{ top: 50 }}>
      <XAxis dataKey="time" minTickGap={150} />
      <YAxis minTickGap={400} />
      <Line type="step" dataKey="value" stroke="#8884d8" isAnimationActive={false} dot={false} />
    </LineChart>
  );
}

// This is used to create a analog chart to display the SHARC data
export function AnalogChart({ chartData }) {
  return (
    <div>
      <LineChart width={1200} height={500} data={chartData}
        margin={{ top: 50 }} >
        <XAxis dataKey="time" minTickGap={150} type="category" allowDuplicatedCategory="false" />
        <YAxis />
        <Line type="natural" dataKey="value" stroke="#8884d8" isAnimationActive={false} dot={false} />
      </LineChart>
    </div>
  );
}



