import React, { useState, useEffect } from 'react'
import Paho from 'paho-mqtt';
import Charts105 from './105/MQTTCharts'
import InfoHeader from './105/MQTTInfoHeader'
import Information105 from './105/MQTTInformation'
import Devices from './105/MQTTDevices'
import Execute105 from './105/Execute/Execute'
import Charts106 from './106/MQTTCharts'
import Information106 from './106/MQTTInformation'
import Execute106 from './106/Execute/Execute'
import Upload from './Upload';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer';

import MQTTError from './105/MQTTError';
// import MQTTSensorConfig from './MQTTSensorConfig'
import { Blocks } from 'react-loader-spinner'
import { upload } from '@testing-library/user-event/dist/upload';

// Create unique Client ID
const clientId = `mqtt_${Math.random().toString(16).slice(3)}`;

// Landing page for MQTT mode
export default function MQTTLanding({ brokerIP, brokerPort, brokerUser, brokerPass, setConnected, deviceID, setMode, setBrokerIP, setBrokerPass, setBrokerPort, setBrokerUser, client, connected, useSSL, setShowError, validateIPaddress, validatePort, errorMessage, showError, mode, setErrorMessage, setWhichSharc, whichSharc }) {
  let tempData = {
    "seq": 1,
    "v": {
      "s0": {
        "v": 0.0,
        "u": ""
      },
      "s1": {
        "v": 1.0,
        "u": ""
      },
      "s2": {
        "v": 0,
        "u": ""
      },
      "s3": {
        "v": 0,
        "u": ""
      }
    }
  }
  let version = ''
  const [dropDown, setDropDown] = useState(false);
  const [ver, setVer] = useState({});
  const [net, setNet] = useState({});
  const [mqtt, setMQTT] = useState({});
  const [rc, setRc] = useState({});
  const [rtu, setRTU] = useState({});
  const [config, setConfig] = useState({})
  const [sensorData, setSensorData] = useState(tempData);
  const [selectedTab, setSelectedTab] = useState('info');
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState([])
  const [openDevicesTab, setOpenDevicesTab] = useState(false);
  const [sensorTopics, setSensorTopics] = useState([])
  const [deviceId, setDeviceId] = useState('');
  const [messages, setMessages] = useState([]);
  const [description, setDescription] = useState('');
  const [destination, setDestination] = useState('');
  const [destinationShort, setDestinationShort] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);


  // Connect to MQTT Broker and get list devices that are available to connect to
  useEffect(() => {
    if (selectedTab === 'charts') {
      var message = new Paho.Message(JSON.stringify({
        "id": "publishIO",
        "v": {
          "io.publish": true
        }
      }));
      message.destinationName = `sharc/${deviceId}/cmd/action`;
      message.qos = 0;

      client.send(message);
    }
  }, [selectedTab])

  async function MQTTconnect() {
    try {
      let connectClient = new Paho.Client(brokerIP, Number(brokerPort), clientId)
      console.log(`Connecting to ${brokerIP}:${brokerPort} as ${clientId}`)
      var options = {
        timeout: 3,
        onSuccess: function () {
          console.log('Getting devices from broker...')
          connectClient.subscribe(`sharc/+/evt/avail`)
          setLoading(false)
          setOpenDevicesTab(true)
        },
        onFailure: function (message) {
          setErrorMessage(`Connection attempt to host ${brokerIP} failed.\n
        Please ensure your MQTT settings are correct.`)
          setShowError(true)
          setConnected(false)
          setLoading(false)

          throw new Error(`Connection attempt to Host ${brokerIP} failed`)
        },
        userName: brokerUser,
        password: brokerPass,
        useSSL: useSSL
      };
      let count = 0
      connectClient.onMessageArrived = function (message) {
        count++;
        if (count === 1) {
          setTimeout(() => {
            connectClient.disconnect();
          }, 1000)
        }
        if (JSON.parse(message.payloadString).v === true) {
          setDevices(prevDevices => [...prevDevices, message.destinationName.split('/')[1]])
        }
        if (!connected || deviceID !== '') {
          connectClient.disconnect()
        }

      }
      connectClient.connect(options)
    } catch (err) {
      setErrorMessage(`Connection attempt to host failed.\n
      Please ensure your MQTT settings are correct.`)
      setShowError(true)
      setConnected(false)
      setLoading(false)
      console.log(err)
    }
  }
  function updateFirmware(link) {
    if (link.includes('https://raw.githubusercontent.com/SharcTech/sharc-support/main/firmware/')) {
      let uploadClient = new Paho.Client(brokerIP, Number(brokerPort), clientId)
      var options = {
        timeout: 3,
        onSuccess: function () {
          let message = new Paho.Message(`{
            "id": "${uuidv4()}",
            "v": {
              "device.ota": {
                "bin": "${link}"
              }
            }
          }`)
          message.destinationName = `sharc/${deviceId}/cmd/action`
          uploadClient.send(message)
        },
        onFailure: function (message) {
          console.log('Failed to Connect')
        },
        userName: brokerUser,
        password: brokerPass,
        useSSL: useSSL
      };
      uploadClient.onMessageDelivered = function (message) {
        console.log('Firmware Successfully Uploaded to Device...')
        uploadClient.disconnect();
        setConnected(false)
        setLoading(false)

      }
      uploadClient.connect(options)
      return 'done'
    } else {
      return 'error'
    }
  }


  // Once device is selected get relevant information from that device
  function getDeviceInfo(deviceID) {
    setDeviceId(deviceID)
    console.log(`Connecting to ${brokerIP}:${brokerPort} as ${clientId}`)
    var options = {
      timeout: 3,
      onSuccess: function () {
        console.log(`Connecting to ${deviceID}...`)
        client.subscribe(`sharc/${deviceID}/evt/#`)
        setLoading(false)
      },
      onFailure: function (message) {
        console.log(`Connection attempt to Device ${deviceID} failed`)
        setErrorMessage(`Connection attempt to Device ${deviceID} failed`)
        setShowError(true)
        setConnected(false)
        setLoading(false)
      },
      userName: brokerUser,
      password: brokerPass,
      useSSL: useSSL

    };
    client.onMessageArrived = function (message) {
      if (message.destinationName.includes('/evt/ver')) {
        let payload = JSON.parse(message.payloadString)
        setWhichSharc(payload.v.hw)
        version = payload.v.hw
        setVer(payload.v)
      }
      if (message.destinationName.includes('/evt/net')) {
        let payload = JSON.parse(message.payloadString)
        setNet(payload.v)
      }
      if (message.destinationName.includes('/evt/mqtt')) {
        let payload = JSON.parse(message.payloadString)
        setMQTT(payload.v)
      }
      if (message.destinationName.includes('/evt/rc')) {
        let payload = JSON.parse(message.payloadString)
        setRc(payload.v)
      }
      if (message.destinationName.includes('/evt/sensor')) {
        let payload = JSON.parse(message.payloadString)
        setConfig(payload.v)
      }
      if (message.destinationName.includes('/evt/rtu')) {
        let payload = JSON.parse(message.payloadString)
        setRTU(payload.v)
      }
      if (message.destinationName.includes('/evt/io')) {
        if (version === '105') {
          console.log(`IO Message Received ${message.payloadString}`)
          let payload = JSON.parse(message.payloadString)
          if (payload.v.hasOwnProperty('s0')) {
            tempData.seq = payload.seq
            tempData.v.s0.v = payload.v.s0.v
            tempData.v.s0.u = payload.v.s0.u
          }
          if (payload.v.hasOwnProperty('s1')) {
            tempData.seq = payload.seq
            tempData.v.s1.v = payload.v.s1.v
            tempData.v.s1.u = payload.v.s1.u
          }
          if (payload.v.hasOwnProperty('s2')) {
            tempData.seq = payload.seq
            tempData.v.s2.v = payload.v.s2.v
            tempData.v.s2.u = payload.v.s2.u
          }
          if (payload.v.hasOwnProperty('s3')) {
            tempData.seq = payload.seq
            tempData.v.s3.v = payload.v.s3.v
            tempData.v.s3.u = payload.v.s3.u
          }
          if (message.destinationName.includes('/evt/io/s0')) {
            tempData.seq = payload.seq
            tempData.v.s0.v = payload.v.s0.v
            tempData.v.s0.u = payload.v.s0.u
          }
          if (message.destinationName.includes('/evt/io/s1')) {
            tempData.seq = payload.seq
            tempData.v.s1.v = payload.v.s1.v
            tempData.v.s1.u = payload.v.s1.u
          }
          if (message.destinationName.includes('/evt/io/s2')) {
            tempData.seq = payload.seq
            tempData.v.s2.v = payload.v.s2.v
            tempData.v.s2.u = payload.v.s2.u
          }
          if (message.destinationName.includes('/evt/io/s3')) {
            tempData.seq = payload.seq
            tempData.v.s3.v = payload.v.s3.v
            tempData.v.s3.u = payload.v.s3.u
          }
          setSensorData(tempData)
          console.log(`IO Topic Received ${message.destinationName}`)
        } else if (version === '106') {
          console.log(`IO Message Received ${message.payloadString}`)
          let payload = JSON.parse(message.payloadString)
          let sensor = message.destinationName.split('/')[4]
          if (!payload.v.e) {
            let currentTopics = sensorTopics
            if (currentTopics.indexOf(sensor) === -1) {
              currentTopics.push(sensor)
            }
            setSensorTopics(currentTopics)
            tempData.v[sensor] = { v: 0, u: "", seq: 0 }
            tempData.v[sensor].seq = payload.seq
            tempData.v[sensor].v = payload.v.v
            tempData.v[sensor].u = payload.v.u
          }
          setSensorData(tempData)
          console.log(`IO Topic Received ${message.destinationName}`)
        }
      }
      if (!message.destinationName.includes('/evt/io')) {
        console.log(`Message Receieved From Broker ${message.payloadString}`);
        console.log("Topic: " + message.destinationName);
        message.destinationNameShort = message.destinationName.split('/').slice(2).join('/')
        message.direction = 'Received'
        message.timeStamp = new Date().toLocaleTimeString()
        setMessages(oldMessages => [...oldMessages, message]);
      }
      if (message.destinationName.includes('evt/avail')) {
        if (message.payloadString.includes(false)) {
          console.log('Device No Longer Available')
        }
      }
    }
    client.onMessageDelivered = function (message) {
      if (!message.payloadString.includes('password')) {
        console.log("Message Sent To Broker: " + message.payloadString);
      } else {
        console.log("Message Sent To Broker but Contains Sensitive Information");
      }
      message.destinationNameShort = message.destinationName.split('/').slice(2).join('/')
      message.timeStamp = new Date().toLocaleTimeString()
      setMessages(oldMessages => [...oldMessages, message]);
      message.direction = 'Sent'
      console.log("Topic: " + message.destinationName);
    }
    client.onConnectionLost = function (responseObject) {
      console.log(`Connection Lost: ${responseObject.errorMessage}`);
    }
    client.connect(options)
  }
  async function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        console.log(event.target.result);
        const buffer = Buffer.from(event.target.result);
        const base64 = buffer.toString('base64');
        console.log('buffer', buffer)
        console.log('b64', base64)
        resolve(base64);
      };

      reader.onerror = (error) => {
        reject("Error reading file: " + error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  async function sendFileToSharc(file, type) {
    setLoading(true)
    if (type === 'cert') {
      console.log(type, file)
      let uploadClient = new Paho.Client(brokerIP, Number(brokerPort), clientId)
      var options = {
        timeout: 3,
        onSuccess: function () {
          let message = new Paho.Message(`{
            "id": "${uuidv4()}",
            "v": {
              "file.save": {
                "name": "client.crt",
                "b64": "${file}"
              }
            }
          }`)
          message.destinationName = `sharc/${deviceId}/cmd/action`
          console.log(message)
          uploadClient.send(message)
        },
        onFailure: function (message) {
          console.log('Failed to Connect')
        },
        userName: brokerUser,
        password: brokerPass,
        useSSL: useSSL
      };
      uploadClient.onMessageDelivered = function (message) {
        console.log('Certificate Successfully Uploaded to Device...')
        // setConnected(false)
        uploadClient.disconnect();
        setLoading(false)
      }
      uploadClient.connect(options)
    } else if (type === 'pk') {
      console.log(type, file)
      let uploadClient = new Paho.Client(brokerIP, Number(brokerPort), clientId)
      var options = {
        timeout: 3,
        onSuccess: function () {
          let message = new Paho.Message(`{
            "id": "${uuidv4()}",
            "v": {
              "file.save": {
                "name": "client.key",
                "b64": "${file}"
              }
            }
          }`)
          message.destinationName = `sharc/${deviceId}/cmd/action`
          console.log(message)
          uploadClient.send(message)
        },
        onFailure: function (message) {
          console.log('Failed to Connect')
        },
        userName: brokerUser,
        password: brokerPass,
        useSSL: useSSL
      };
      uploadClient.onMessageDelivered = function (message) {
        console.log('Private Key Successfully Uploaded to Device...')
        // setConnected(false)
        uploadClient.disconnect();
        setLoading(false)
      }
      uploadClient.connect(options)
    } else {
      console.log('Wrong Type')
    }
    setLoading(false)
  }

  // On page render connect to MQTT Broker
  useEffect(() => {
    MQTTconnect()
  }, [])

  return (
    <div id="information" onClick={(e) => {
      if (dropDown) {
        setDropDown(false)
      }
    }}>
      <div className="information-container" onClick={(e) => {
      }}>
        {/* If the browser has the information from the device, display the header */}
        {!loading && <InfoHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} setMode={setMode} setConnected={setConnected} deviceId={deviceId} dropDown={dropDown} setDropDown={setDropDown} brokerIP={brokerIP} brokerPort={brokerPort} brokerPass={brokerPass} brokerUser={brokerUser} clientId={clientId} net={net} client={client} mqtt={mqtt} />}

        {(!loading && selectedTab === 'upload') && <Upload setSelectedTab={setSelectedTab} updateFirmware={updateFirmware} readFile={readFile}
          sendFileToSharc={sendFileToSharc} />}
        {/* If the browser has the information from the device, display the information of the device (This is the first page by default) */}
        {(selectedTab === 'info' && !loading && whichSharc === '105') && <Information105 net={net} rc={rc} ver={ver} client={client} clientId={clientId} deviceId={deviceId} setConnected={setConnected} dropDown={dropDown} config={config} validateIPaddress={validateIPaddress} validatePort={validatePort} errorMessage={errorMessage} showError={showError} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} mqtt={mqtt} whichSharc={whichSharc} />}

        {/* If the browser has the information from the device, display the charts */}
        {(selectedTab === 'charts' && !loading && whichSharc === '105') && <Charts105 selectedTab={selectedTab} deviceId={deviceId} sensorData={sensorData} />}

        {/* If the browser has the information from the device, display the execute page */}
        {(selectedTab === 'execute' && !loading && whichSharc === '105') && <Execute105 deviceId={deviceId} brokerIP={brokerIP} brokerPort={brokerPort} brokerUser={brokerUser} brokerPass={brokerPass} clientId={clientId} setMessages={setMessages} messages={messages} description={description} setDescription={setDescription} destination={destination} setDestination={setDestination} destinationShort={destinationShort} setDestinationShort={setDestinationShort} setConnected={setConnected} client={client} loggedIn={loggedIn} setLoggedIn={setLoggedIn} setSelectedTab={setSelectedTab} />}

        {(selectedTab === 'info' && !loading && whichSharc === '106') && <Information106 net={net} rc={rc} ver={ver} client={client} clientId={clientId} deviceId={deviceId} setConnected={setConnected} dropDown={dropDown} config={config} validateIPaddress={validateIPaddress} validatePort={validatePort} errorMessage={errorMessage} showError={showError} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} mqtt={mqtt} whichSharc={whichSharc} rtu={rtu} />}

        {/* If the browser has the information from the device, display the charts */}
        {(selectedTab === 'charts' && !loading && whichSharc === '106') && <Charts106 selectedTab={selectedTab} deviceId={deviceId} sensorData={sensorData} sensorTopics={sensorTopics} />}

        {/* If the browser has the information from the device, display the execute page */}
        {(selectedTab === 'execute' && !loading && whichSharc === '106') && <Execute106 deviceId={deviceId} brokerIP={brokerIP} brokerPort={brokerPort} brokerUser={brokerUser} brokerPass={brokerPass} clientId={clientId} setMessages={setMessages} messages={messages} description={description} setDescription={setDescription} destination={destination} setDestination={setDestination} destinationShort={destinationShort} setDestinationShort={setDestinationShort} setConnected={setConnected} client={client} loggedIn={loggedIn} setLoggedIn={setLoggedIn} setSelectedTab={setSelectedTab} />}


        {/* While loading display the loader */}
        {loading && <div className="loading-container">
          <Blocks visible={true} height="150" width="150" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
        </div>}

        {/* If a device has not been selected show the devices list */}
        {openDevicesTab &&
          <Devices getDeviceInfo={getDeviceInfo} devices={devices} setOpenDevicesTab={setOpenDevicesTab} setMode={setMode} setConnected={setConnected} />}
      </div>
    </div>
  )
}