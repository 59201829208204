import InfoHeader from './InfoHeader'
import Sensors from './Sensors'
import Charts from './Charts'
import DeviceInformation from './DeviceInformation'
import { useState, useEffect } from 'react'
import { Blocks } from 'react-loader-spinner'

// BLE landing Page
export default function Information({ connected, setConnected, sharcInfo, setSharcInfo, getInfo, findDevice, getData, disconnect, changeNetConfig, changeMqttConfig, rebootDevice, validateIPaddress, validateSubnet, validatePort, sensorData, saveValues, tsData, unitsData, connectedDevice, saveConfig, showError, errorMessage, setShowError, mode, setErrorMessage, changeNetType, whichSharc }) {

  const [selectedTab, setSelectedTab] = useState('info')
  const [selectedChart, setSelectedChart] = useState('start')
  const [currentSensor, setCurrentSensor] = useState(null)
  const [dropDown, setDropDown] = useState(false);

  // If the browser has loaded the information off of the SHARC
  return JSON.stringify(sharcInfo) !== '{}' ? (

    <div id="information" onClick={(e) => {

      // This is used to close the dropdown power options when clicking outside of the list
      if (dropDown) {
        setDropDown(false)
      }
    }}>

      <div className="information-container">

        {/* Header */}
        <InfoHeader setSelectedTab={setSelectedTab} selectedTab={selectedTab} setConnected={setConnected} disconnect={disconnect} connectedDevice={connectedDevice} dropDown={dropDown} setDropDown={setDropDown} rebootDevice={rebootDevice} saveValues={saveValues} getInfo={getInfo} setSharcInfo={setSharcInfo}/>

        {/* If user has selected the info tab */}
        {selectedTab === 'info' && <DeviceInformation setSelectedChart={setSelectedChart} selectedChart={selectedChart} getData={getData} setCurrentSensor={setCurrentSensor} sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} setConnected={setConnected} changeNetConfig={changeNetConfig} getInfo={getInfo} validateIPaddress={validateIPaddress} validateSubnet={validateSubnet} changeMqttConfig={changeMqttConfig} validatePort={validatePort} rebootDevice={rebootDevice} saveValues={saveValues} saveConfig={saveConfig} showError={showError} errorMessage={errorMessage} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} changeNetType={changeNetType} whichSharc={whichSharc}
        />}

        {/* If user has selected the charts tab */}
        {selectedTab === 'charts' && <Sensors setSelectedChart={setSelectedChart} selectedChart={selectedChart} getData={getData} setCurrentSensor={setCurrentSensor} />}
        {selectedTab === 'charts' && <Charts setSelectedChart={setSelectedChart} selectedChart={selectedChart} sensorData={sensorData} currentSensor={currentSensor} tsData={tsData} unitsData={unitsData} />}
      </div>
    </div>
  ) : (

    // If the browser is still loading the information from the SHARC
    <div className="loading-container">
      <Blocks visible={true} height="150" width="150" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
    </div>
  )
}