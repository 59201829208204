import React, { useState } from 'react';
import moment from 'moment'
import { SteppedChart, AnalogChart } from './Charts/SensorCharts'

// This is used to display the sensor data off of the connected SHARC
export default function Charts({ setSelectedChart, selectedChart, sensorData, currentSensor, unitsData, tsData }) {

  const [chartData, setChartData] = useState([]);

  // Every 10 milliseconds get the value of the sensor and add it to the charts data array
  setTimeout(() => {
    var chartObj = { time: moment().format("h:mm:ss a"), value: sensorData }
    if (chartData.length > 500) {
      chartData.shift()
    }
    setChartData(currentData => [...currentData, chartObj])
  }, 10)

  return (
    <div id="charts">
      {selectedChart === 'start' && <div className="start-container">
        <div className="start-arrow">&uarr;</div>
        <div className="start-info">Please select which sensor you are currently using</div>
      </div>}

      {/* Depending on which sensor is selected, display that relevant chart */}
      {selectedChart === 'd-npn' &&
        <>
          {(
            <div className="charts-container">
              <div className="charts-information">
                <div className="charts-info-item"><div className="charts-value-header">Value</div>
                  <div className="charts-value-info">{sensorData}</div></div>
                <div className="charts-info-item"><div className="charts-value-header">Units</div>
                  <div className="charts-value-info">{unitsData}</div></div>
                {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                  <div className="charts-value-info">{tsData}</div></div> */}
              </div>
              <SteppedChart chartData={chartData} setChartData={setChartData} sensorData={sensorData} />
            </div>
          )}
        </>
      }
      {selectedChart === 'd-pnp' &&
        <>
          {(
            <div className="charts-container">
              <div className="charts-information">
                <div className="charts-info-item"><div className="charts-value-header">Value</div>
                  <div className="charts-value-info">{sensorData}</div></div>
                <div className="charts-info-item"><div className="charts-value-header">Units</div>
                  <div className="charts-value-info">{unitsData}</div></div>
                {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                  <div className="charts-value-info">{tsData}</div></div> */}
              </div>
              <SteppedChart chartData={chartData} setChartData={setChartData} sensorData={sensorData} />
            </div>

          )}
        </>
      }
      {selectedChart === 'a-010' &&
        <>
          {(
            <div className="charts-container">
              <div className="charts-information">
                <div className="charts-info-item"><div className="charts-value-header">Value</div>
                  <div className="charts-value-info">{sensorData}</div></div>
                <div className="charts-info-item"><div className="charts-value-header">Units</div>
                  <div className="charts-value-info">{unitsData}</div></div>
                {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                  <div className="charts-value-info">{tsData}</div></div> */}
              </div>
              <AnalogChart chartData={chartData} setChartData={setChartData} sensorData={sensorData} />

            </div>
          )}
        </>
      }

      {selectedChart === 'a-420' &&
        <>
          {(
            <div className="charts-container">
              <div className="charts-information">
                <div className="charts-info-item"><div className="charts-value-header">Value</div>
                  <div className="charts-value-info">{sensorData}</div></div>
                <div className="charts-info-item"><div className="charts-value-header">Units</div>
                  <div className="charts-value-info">{unitsData}</div></div>
                {/* <div className="charts-info-item"><div className="charts-value-header">Condition</div>
                  <div className="charts-value-info">{tsData}</div></div> */}
              </div>
              <AnalogChart chartData={chartData} setChartData={setChartData} sensorData={sensorData} />

            </div>
          )}
        </>
      }
    </div>
  )
}