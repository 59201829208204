import Paho from 'paho-mqtt';

export default function MQTTError({ setShowError, errorMessage, step, setErrorMessage }) {
    return (
        <div id="mqtt-modal" onMouseDown={(e) => {
            setShowError(false)
        }}>
            <div className="modal-container" onMouseDown={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-header">
                    <button className="exit hidden">X</button>
                    <div className="modal-title">{step}</div>
                    <button className="exit" onClick={(e) => {
                        setShowError(false)
                        setErrorMessage('')
                    }}>X</button>
                </div>
                <form className="modal-form">
                    <div>{errorMessage}</div>
                    <div className='reboot-container'>
                        <div className="reboot cancel"><button onClick={(e) => {
                            setShowError(false)
                            setErrorMessage('')
                        }}>Go Back</button></div>
                    </div>
                </form>
            </div>
        </div>
    )
}