/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

// Characteristics to save and reboot device
var saveBLE = '00000001-0002-1337-1337-000000000005';
var saveMQTT = '00000003-0002-1337-1337-000000000005';

// This is used to save all changes and reboot the device into whichever mode the user chooses
export default function Save({ setOpenSave, setConnected, saveValues }) {

  return (
    <div id="device-modal" onMouseDown={() => {
      setOpenSave(false);
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation();
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Saving changes will reboot the Sharc, are you sure you want to continue?</div>

          <button className="exit" onClick={() => {
            setOpenSave(false);
          }}>X</button>
        </div>
        <form className="modal-form" onSubmit={(e) => {
          e.preventDefault();
        }}>
          <div className="reboot-container">
            <div className="reboot-container-options">
              <div className="reboot"><button onClick={(e) => {
                e.preventDefault();
                saveValues(saveBLE)
                  .then(() => {
                    setOpenSave(false);
                    setConnected(false);
                  })
                  .catch((err) => console.log(err.message));
              }}>Reboot into BLE</button></div>
              <div className="reboot"><button onClick={(e) => {
                e.preventDefault();
                saveValues(saveMQTT)
                  .then(() => {
                    setOpenSave(false);
                    setConnected(false);
                  })
                  .catch((err) => console.log(err.message));
              }}>Reboot into MQTT</button></div>
            </div>
          </div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault();
            setOpenSave(false);
          }}>Cancel</button></div>
        </form>
      </div>
    </div>
  );
}