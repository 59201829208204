import Header from './Header'
import Instructions from './Instructions'
import Connect from './Connect'
import Support from './Support'
import { useEffect } from 'react'


// BLE setup landing page
export default function Setup({ connected, setConnected, setSharcInfo, findDevice, getInfo, setMode, setShowError, showError, errorMessage, setErrorMessage }) {

  // If browser is supported
  return navigator?.bluetooth ? (
    <div id="setup">
      <Header connected={connected} />
      <Instructions />
      <Connect connected={connected} setConnected={setConnected} setSharcInfo={setSharcInfo} findDevice={findDevice} getInfo={getInfo} setMode={setMode} setShowError={setShowError} showError={showError} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
    </div>
  ) : (
    // If not
    <div id="setup">
      <Header connected={connected} />
      <Support setMode={setMode} />
    </div>
  )
}