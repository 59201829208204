import { useEffect, useState } from 'react'
import EditIcon from '../../../assets/EditIcon.png'
import BLESensorConfig from './Settings/BLESensorConfig'
import DeviceSettings from './Settings/DeviceSettings'
import MQTTSettings from './Settings/MQTTSettings'


// This is used to show the user the relevant information off of the SHARC and allow editing of settings
export default function DeviceInformation({ sharcInfo, setSharcInfo, setConnected, rebootDevice, getData, validatePort, validateSubnet, validateIPaddress, changeNetConfig, getInfo, changeMqttConfig, saveValues, saveConfig, showError, errorMessage, setShowError, mode, setErrorMessage, changeNetType, whichSharc }) {
  const [openMQTT, setOpenMQTT] = useState(false);
  const [openDevice, setOpenDevice] = useState(false);
  const [openChangeConfig, setOpenChangeConfig] = useState(false);
  const [connectionType, setConnectionType] = useState(sharcInfo.connectionType);
  const [connectionQual, setConnectionQual] = useState(sharcInfo.connectionQuality);
  const [hasOutDatedInfo, setHasOutDatedInfo] = useState(false)
  const [staticIp, setStaticIp] = useState(sharcInfo.staticIp)
  const [ip, setIp] = useState(sharcInfo.ip)
  const [subnet, setSubnet] = useState(sharcInfo.sub)
  const [gateway, setGateway] = useState(sharcInfo.gateway)
  const [dns, setDNS] = useState(sharcInfo.dns)

  useEffect(() => {
    setConnectionType(sharcInfo.connectionType)
    setConnectionQual(sharcInfo.connectionQuality)
    setHasOutDatedInfo(false)
    setStaticIp(sharcInfo.staticIp)
    setIp(sharcInfo.ip)
    setSubnet(sharcInfo.sub)
    setGateway(sharcInfo.gateway)
    setDNS(sharcInfo.dns)
  }, [sharcInfo])


  return (
    <div className="device-info-settings">
      <div id="device-info">

        {/* If the user has selected the network settings */}
        {openDevice && <DeviceSettings setOpenDevice={setOpenDevice} sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} validatePort={validatePort} validateSubnet={validateSubnet} validateIPaddress={validateIPaddress} changeNetConfig={changeNetConfig} getInfo={getInfo} changeNetType={changeNetType} connectionType={connectionType} connectionQual={connectionQual} hasOutDatedInfo={hasOutDatedInfo} setConnectionType={setConnectionType} setConnectionQual={setConnectionQual} setHasOutDatedInfo={setHasOutDatedInfo} staticIp={staticIp} setStaticIp={setStaticIp} ip={ip} setIp={setIp} subnet={subnet} setSubnet={setSubnet} gateway={gateway} setGateway={setGateway} dns={dns} setDNS={setDNS} whichSharc={whichSharc} />}

        {/* If the user has selected the MQTT settings */}
        {openMQTT && <MQTTSettings setOpenMQTT={setOpenMQTT} sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} validatePort={validatePort} validateIPaddress={validateIPaddress} changeMqttConfig={changeMqttConfig} getInfo={getInfo}
          showError={showError} errorMessage={errorMessage} setShowError={setShowError} setErrorMessage={setErrorMessage} whichSharc={whichSharc}
        />}

        {openChangeConfig && <BLESensorConfig sharcInfo={sharcInfo} setSharcInfo={setSharcInfo} setOpenChangeConfig={setOpenChangeConfig} getInfo={getInfo} saveConfig={saveConfig} showError={showError} errorMessage={errorMessage} setShowError={setShowError} setErrorMessage={setErrorMessage} whichSharc={whichSharc} />}

        <div className="device-info-main">
          <div className="device-info-title">Device Information</div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">Manufacturer: </div> <div className="device-info-desc">{sharcInfo.name}</div></div>
            <div className="device-info-information"><div className="device-info-name">Model: </div> <div className="device-info-desc">{sharcInfo.model}</div></div>
            <div className="device-info-information"><div className="device-info-name">Serial Number: </div> <div className="device-info-desc">{sharcInfo.serial}</div></div>
            {sharcInfo.adoptionKey && <div className="device-info-information"><div className="device-info-name">Adoption Key: </div> <div className="device-info-desc">{sharcInfo.adoptionKey}</div></div>}
            <div className="device-info-information"><div className="device-info-name">Mac Address: </div> <div className="device-info-desc">{sharcInfo.mac}</div></div>
            <div className="device-info-information"><div className="device-info-name">Hardware Revision: </div> <div className="device-info-desc">{sharcInfo.hardware}</div></div>
            <div className="device-info-information"><div className="device-info-name">Firmware Revision: </div> <div className="device-info-desc">{sharcInfo.firmware}</div></div>
            <div className="device-info-information"><div className="device-info-name">Software Revision: </div> <div className="device-info-desc">{sharcInfo.software}</div></div>
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenDevice(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">Network Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            {connectionType && <div className="device-info-information"><div className="device-info-name">Connection Type:</div><div className="device-info-desc">{connectionType}</div></div>}
            {!hasOutDatedInfo && <>
              {/* <div className="device-info-information"><div className="device-info-name">MAC Address:</div><div className="device-info-desc">{net.mac}</div></div> */}
              {connectionType && <div className="device-info-information"><div className="device-info-name">IP Mode:</div><div className="device-info-desc">{(staticIp === 1 || staticIp === '1') ? 'Static' : 'DHCP'}</div></div>}
              {connectionQual && <div className="device-info-information"><div className="device-info-name">Connection Quality:</div><div className="device-info-desc">{connectionQual}</div></div>}
              <div className="device-info-information"><div className="device-info-name">Device IP Address:</div><div className="device-info-desc">{ip}</div></div>
              <div className="device-info-information"><div className="device-info-name">Network Mask:</div><div className="device-info-desc">{subnet}</div></div>
              <div className="device-info-information"><div className="device-info-name">Gateway IP Address:</div><div className="device-info-desc">{gateway}</div></div>
              <div className="device-info-information"><div className="device-info-name">DNS Server IP Address:</div><div className="device-info-desc">{dns}</div></div></>}
            {hasOutDatedInfo && <>
              <div className="device-info-information" style={{ justifyContent: 'center', marginTop: 30, color: "#cc0000" }}><div className="device-info-name">Connection Type Has Changed</div></div>
              <div className="device-info-information" style={{ justifyContent: 'center', color: "#cc0000" }}><div className="device-info-name">Save and Restart to See New Network Information</div></div>
            </>}
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenMQTT(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">MQTT Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">Broker IP:</div><div className="device-info-desc">{sharcInfo.brokerIP}</div></div>
            <div className="device-info-information"><div className="device-info-name">Broker Port:</div><div className="device-info-desc">{sharcInfo.brokerPort}</div></div>
            <div className="device-info-information"><div className="device-info-name">Broker User:</div><div className="device-info-desc">{sharcInfo.brokerUser}</div></div>
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenChangeConfig(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">Sensor Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            {/* <div className="device-info-information"><div className="device-info-name">MQTT Payload Format</div><div className="device-info-desc">{sharcInfo.agg === "1" ? 'Aggregation On' : 'Aggregation Off'}</div></div> */}
            <div className="device-info-information"><div className="device-info-name">PNP Digital Input</div><div className="device-info-desc">{sharcInfo.s0Mode[0].toUpperCase() + sharcInfo.s0Mode.substring(1)}</div></div>
            <div className="device-info-information"><div className="device-info-name">NPN Digital Input</div><div className="device-info-desc">{sharcInfo.s1Mode[0].toUpperCase() + sharcInfo.s1Mode.substring(1)}</div></div>
          </div>
        </div>
      </div>
    </div>
  )
}