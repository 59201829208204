import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';
import { useState, useEffect } from 'react';



// Used to update network information of the connected SHARC
export default function DeviceSettings({ setOpenDevice, net, clientId, deviceId, setConnected, staticIp, setStaticIp, ip, setIp, subnet, setSubnet, gateway, setGateway, dns, setDNS, client, connectionType, setConnectionType, ssid, setSSID, originalConnectionType, setHasOutDatedInfo, lanFallbackS, setLanFallbackS }) {

  const [wifiPass, setWifiPass] = useState('');
  const [newConnectionType, setNewConnectionType] = useState(connectionType)
  const [newSSID, setNewSSID] = useState(ssid)
  const [newLanFallbackS, setNewLanFallbackS] = useState(lanFallbackS)
  const [newStaticIp, setNewStaticIp] = useState(staticIp)
  const [newIp, setNewIp] = useState(ip)
  const [newSubnet, setNewSubnet] = useState(subnet)
  const [newGateway, setNewGateway] = useState(gateway)
  const [newDNS, setNewDNS] = useState(dns)


  return (
    <div id="device-modal" onMouseDown={(e) => {
      setOpenDevice(false)
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit Network Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenDevice(false)
          }}>X</button>
        </div>
        <form className="modal-form" onSubmit={(e) => {
          e.preventDefault()
        }}>
          {newConnectionType && <div className="form-container">
            <label className="checkbox">Use Wifi?: <input type="checkbox" checked={newConnectionType === "WLAN"} onChange={(e) => {
              setNewConnectionType(e.target.checked ? "WLAN" : "LAN")
            }} ></input></label>
          </div>}
          {newConnectionType === 'WLAN' && <>
            <div className="form-container">
              <label>SSID: <input type='text' value={newSSID} onChange={(e) => {
                setNewSSID((e.target.value))
              }}></input></label>
            </div>
            <div className="form-container">
              <label>Change Password: <input type='password' value={wifiPass} onChange={(e) => {
                setNewWifiPass((e.target.value))
              }}></input></label>
            </div>
            <div className="form-container">
              <label>Lan Fallback (Seconds): <input type='number' value={newLanFallbackS} onChange={(e) => {
                setNewLanFallbackS((e.target.value))
              }}></input></label>
            </div>
          </>}
          {(newConnectionType === originalConnectionType) && <div className="form-container">
            <label className="checkbox">Static IP: <input type="checkbox" checked={newStaticIp === '1' || newStaticIp === true || newStaticIp === 1} onChange={(e) => {
              setNewStaticIp(e.target.checked ? 1 : 0)
            }} ></input></label>
          </div>}
          {((newStaticIp === '1' || newStaticIp === true || newStaticIp === 1) && newConnectionType === originalConnectionType) &&
            <><div className="form-container">
              <label>IP Address: <input type='text' value={newIp} onChange={(e) => {
                setNewIp((e.target.value))
              }}></input></label>
            </div>
              <div className="form-container">
                <label>Subnet Mask: <input type='text' value={newSubnet} onChange={(e) => {
                  setNewSubnet((e.target.value))
                }}></input></label>
              </div>
              <div className="form-container">
                <label>Gateway: <input type='text' value={newGateway} onChange={(e) => {
                  setNewGateway((e.target.value))
                }}></input></label>
              </div>
              <div className="form-container">
                <label>DNS: <input type='text' value={newDNS} onChange={(e) => {
                  setNewDNS((e.target.value))
                }}></input></label>
              </div></>}
          <div className="reboot-container">
            <div className="save"><button onClick={(e) => {
              let message;
              let connectionTypeMessage;
              if (originalConnectionType !== newConnectionType) {
                if (newConnectionType === 'LAN') {
                  connectionTypeMessage = new Paho.Message(`{
                    "id": "${uuidv4()}",
                    "v": { 
                      "device.network.lan" : true
                    }
                  }`)
                } else {
                  connectionTypeMessage = new Paho.Message(`{
                    "id": "${uuidv4()}",
                    "v": { 
                      "device.network.wlan" : true
                    }
                  }`)
                }
                connectionTypeMessage.destinationName = `sharc/${deviceId}/cmd/action`
                client.send(connectionTypeMessage)
                setHasOutDatedInfo(true)
              } else {
                setHasOutDatedInfo(false)
                if (newStaticIp === 1) {
                  if (newIp === '0.0.0.0' || newDNS === '0.0.0.0') {
                    console.log('IP/DNS can\'t be 0.0.0.0')
                    alert('IP/DNS can\'t be 0.0.0.0')
                    return;
                  } else {

                    if (newConnectionType === 'LAN') {
                      message = new Paho.Message(`{
                      "id": "${uuidv4()}",
                      "v": {
                        "lan.static_ip": ${newStaticIp},
                        "lan.ip_config.ip": "${newIp}",
                        "lan.ip_config.gw": "${newGateway}",
                        "lan.ip_config.mask": "${newSubnet}",
                        "lan.ip_config.dns": "${newDNS}"
                      }
                    }`)
                    } else {
                      message = new Paho.Message(`{
                      "id": "${uuidv4()}",
                      "v": {
                        "wlan.static_ip": ${newStaticIp},
                        "wlan.ip_config.ip": "${newIp}",
                        "wlan.ip_config.gw": "${newGateway}",
                        "wlan.ip_config.mask": "${newSubnet}",
                        "wlan.ip_config.dns": "${newDNS}"
                      }
                    }`)
                    }
                    message.destinationName = `sharc/${deviceId}/cmd/cfg`
                    client.send(message)
                  }

                } else {
                  let message;
                  if (newConnectionType === 'LAN') {
                    message = new Paho.Message(`{
                      "id": "${uuidv4()}",
                      "v": {
                        "lan.static_ip": 0
                      }
                    }`)
                  } else {
                    message = new Paho.Message(`{
                      "id": "${uuidv4()}",
                      "v": {
                        "wlan.static_ip": 0
                      }
                    }`)


                  }
                  message.destinationName = `sharc/${deviceId}/cmd/cfg`
                  client.send(message)
                }
              }
              if (wifiPass) {
                let wlanInfo = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": { 
                    "wlan.ssid": "${newSSID}",
                    "wlan.pass": "${wifiPass}"
                  }
                }`)
                wlanInfo.destinationName = `sharc/${deviceId}/cmd/cfg`
                client.send(wlanInfo)
              }
              if (newConnectionType === 'WLAN') {
                let lanFallback = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": { 
                    "wlan.lan_fallback_s": ${newLanFallbackS}
                  }
                }`)
                lanFallback.destinationName = `sharc/${deviceId}/cmd/cfg`
                client.send(lanFallback)
              }

              setOpenDevice(false)
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenDevice(false)
            }}>Cancel</button></div>

          </div>
        </form>
      </div>
    </div>
  )
}