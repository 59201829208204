
import Logo from '../../assets/Logo.png'

// Page to select which mode will be used to communicate with the device (MQTT/BLE)
export default function ModeSelect({ setMode }) {
  return (
    <div id="modes">
      <div className="mode-container">
        <div id="info-header">
          <div className="info-header-container-centered">
            <div style={{ padding: 0 }} className="logo">
              <a href="/"><img id="logo" src={Logo} alt="Sharc" style={{ width: '450px', boxSizing: 'borderBox' }} ></img></a>
            </div>
          </div>
        </div>
        <div className="mode-question">How would you like to connect to your SHARC?</div>
        <div className="mode-button-container">
          <button className='mode-button' onClick={(e) => {
            setMode('ble')
          }}>Bluetooth</button>
          <button className='mode-button' onClick={(e) => {
            setMode('mqtt')
          }}>MQTT</button>
        </div>
      </div>
    </div>
  )
}