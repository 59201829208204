import { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';
import Help from '../../../../assets/Help.webp';
import MQTTError from '../MQTTError';





// This is used to change what MQTT broker the device connects to
export default function ChangeConfig({ setOpenChangeConfig, deviceId, client, npnOption, npnEdge, npnPeriod, npnCount, pnpOption, pnpEdge, pnpCount, pnpPeriod, s2Dead, s3Dead, setNpnOption, setNpnEdge, setNpnPeriod, setNpnCount, setPnpOption, setPnpEdge, setPnpCount, setPnpPeriod, setS2Dead, setS3Dead, errorMessage, showError, setShowError, setErrorMessage, s2Transform, setS2Transform, s3Transform, setS3Transform }) {
  // Only bring back numbers and then disect?
  const [s2Units, setS2Units] = useState('v');
  const [s2Upper, setS2Upper] = useState(10);
  const [s2Lower, setS2Lower] = useState(0);
  const [s2Hi, setS2Hi] = useState(10);
  const [s2Lo, setS2Lo] = useState(0);
  const [s2Multiplier, setS2Multiplier] = useState(0);
  const [s3Units, setS3Units] = useState('mA');
  const [s3Upper, setS3Upper] = useState(20);
  const [s3Lower, setS3Lower] = useState(4);
  const [s3Hi, setS3Hi] = useState(20);
  const [s3Lo, setS3Lo] = useState(4);
  const [s3Multiplier, setS3Multiplier] = useState(4);
  const [newNpnOption, setNewNpnOption] = useState(npnOption);
  const [newNpnEdge, setNewNpnEdge] = useState(npnEdge);
  const [newNpnPeriod, setNewNpnPeriod] = useState(npnPeriod);
  const [newNpnCount, setNewNpnCount] = useState(npnCount);
  const [newPnpOption, setNewPnpOption] = useState(pnpOption);
  const [newPnpEdge, setNewPnpEdge] = useState(pnpEdge);
  const [newPnpCount, setNewPnpCount] = useState(pnpCount);
  const [newPnpPeriod, setNewPnpPeriod] = useState(pnpPeriod);
  const [news2Dead, setNews2Dead] = useState(s2Dead);
  const [news3Dead, setNews3Dead] = useState(s3Dead);
  const [news2Transform, setNews2Transform] = useState(s2Transform);
  const [news3Transform, setNews3Transform] = useState(s3Transform);

  useEffect(() => {
    var regexPattern = /[-+]?(\d*\.\d+|\d+)/g;

    // Extract all numeric values from the string
    setS2Units(s2Transform.split(',')[1].replace(/'/g, '').trim())
    var s2Matches = s2Transform.match(regexPattern).map(parseFloat);
    console.log(s2Matches)
    setS2Multiplier(s2Matches[1])
    setS2Upper(s2Matches[5])
    setS2Lower(s2Matches[6])
    setS2Hi(s2Matches[3])
    setS2Lo(s2Matches[2])
    setS3Units(s3Transform.split(',')[1].replace(/'/g, '').trim())
    var s3Matches = s3Transform.match(regexPattern).map(parseFloat);
    setS3Multiplier(s3Matches[1])
    setS3Upper(s3Matches[5])
    setS3Lower(s3Matches[6])
    setS3Hi(s3Matches[3])
    setS3Lo(s3Matches[2])

  }, [s2Transform, s3Transform])

  function formulaBuilder() {
    var volt = `(float('{:.1f}'.format((v * ${Number(s2Multiplier)} - ${Number(s2Lo)}) / (${Number(s2Hi)} - ${Number(s2Lo)}) * (${Number(s2Upper)} - ${Number(s2Lower)}) + ${Number(s2Lower)})), '${s2Units}', False)`
    var mA = `(float('{:.1f}'.format((v * ${Number(s3Multiplier)} - ${Number(s3Lo)}) / (${Number(s3Hi)} - ${Number(s3Lo)}) * (${Number(s3Upper)} - ${Number(s3Lower)}) + ${Number(s3Lower)})), '${s3Units}', False)`
    var formulas = {
      volt,
      mA
    }
    return formulas
  }

  function messageBuilder() {
    var s0 = '';
    var s1 = '';
    var s2Convert = formulaBuilder().volt;
    var s3Convert = formulaBuilder().mA;
    var s2 = `"sensor.s2.deadband": ${Number(news2Dead)},
        "sensor.s2.transform": "${s2Convert}"`
    var s3 = `"sensor.s3.deadband": ${Number(news3Dead)},
        "sensor.s3.transform": "${s3Convert}"`;

    if (newPnpOption === 'switch') {
      s0 = `"sensor.s0.mode": "switch",
        "sensor.s0.transform": "(v, '/', False)"`
    } else if (newPnpOption === 'counter') {
      s0 = `"sensor.s0.mode": "counter",
        "sensor.s0.edge": "${newPnpEdge}",
        "sensor.s0.persist": ${JSON.parse(newPnpCount)},
        "sensor.s0.transform": "(v, 'count', False)"`
    } else {
      s0 = `"sensor.s0.mode": "accumulator",
        "sensor.s0.edge": "${newPnpEdge}",
        "sensor.s0.period": ${JSON.parse(newPnpPeriod)},
        "sensor.s0.transform": "(v, 'count', False)"`
    }
    if (newNpnOption === 'switch') {
      s1 = `"sensor.s1.mode": "switch",
        "sensor.s1.transform": "(v, '/', False)"`
    } else if (newNpnOption === 'counter') {
      s1 = `"sensor.s1.mode": "counter",
        "sensor.s1.edge": "${newNpnEdge}",
        "sensor.s1.transform": "(v, 'count', False)"`
    } else {
      s1 = `"sensor.s1.mode": "accumulator",
        "sensor.s1.edge": "${newNpnEdge}",
        "sensor.s1.period": ${newNpnPeriod},
        "sensor.s1.calibrate": "(v, False)",
        "sensor.s1.transform": "(v, 'count', False)"`
    }

    var message = `{
      "id": "${uuidv4()}",
      "v": {
        ${s0},
        ${s1},
        ${s2},
        ${s3}
      }
    }`
    return message;
  }

  return (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setOpenChangeConfig(false)
    }}>
      {showError && <MQTTError showError={showError} errorMessage={errorMessage} setShowError={setShowError} step={'Error Changing Config'} setErrorMessage={setErrorMessage} />}
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit Sensor Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenChangeConfig(false)
          }}>X</button>
        </div>
        <form className="modal-form">
          <div id="sensor-landing">
            <div className="sensor-landing-container">
              <div className="pnp-config">
                <div className="gen-config-title">PNP Digital Input Configuration</div>
                {<div className="gen-config-option">
                  <div className="gen-config-name">Function</div>
                  <select className="config-dropdown" value={newPnpOption} onChange={(e) => {
                    setNewPnpOption(e.target.value)
                  }}>
                    <option value="switch">Switch</option>
                    <option value="counter">Counter</option>
                    <option value="accumulator">Accumulator</option>
                  </select>
                  <button disabled={true} title="This defines what type of sensor is in use" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {(newPnpOption === 'counter' || newPnpOption === 'accumulator') && <div className="gen-config-option">
                  <div className="gen-config-name">Edge</div>
                  <select className="config-dropdown" value={newPnpEdge} onChange={(e) => {
                    setNewPnpEdge(e.target.value)
                  }}>
                    <option value="any">Any</option>
                    <option value="rising">Rising</option>
                    <option value="falling">Falling</option>
                  </select>
                  <button disabled={true} title="This defines what edge the sensor will count on" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {newPnpOption === 'accumulator' && <div className="gen-config-option">
                  <div className="gen-config-name">Period</div>
                  <input className="period-input" type="text" placeholder="ms" value={newPnpPeriod} onChange={(e) => {
                    setNewPnpPeriod(e.target.value)
                  }}></input>
                  <button disabled={true} title="This defines how long the accumulator should count for in ms" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
              </div>
              <div className="npn-config">
                <div className="gen-config-title">NPN Digital Input Configuration</div>
                {<div className="gen-config-option">
                  <div className="gen-config-name">Function</div>
                  <select className="config-dropdown" value={newNpnOption} onChange={(e) => {
                    setNewNpnOption(e.target.value)
                  }}>
                    <option value="switch">Switch</option>
                    <option value="counter">Counter</option>
                    <option value="accumulator">Accumulator</option>
                  </select>
                  <button disabled={true} title="This defines what type of sensor is in use" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {(newNpnOption === 'counter' || newNpnOption === 'accumulator') && <div className="gen-config-option">
                  <div className="gen-config-name">Edge</div>
                  <select className="config-dropdown" value={newNpnEdge} onChange={(e) => {
                    setNewNpnEdge(e.target.value)
                  }}>
                    <option value="any">Any</option>
                    <option value="rising">Rising</option>
                    <option value="falling">Falling</option>
                  </select>
                  <button disabled={true} title="This defines what edge the sensor will count on" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {newNpnOption === 'accumulator' && <div className="gen-config-option">
                  <div className="gen-config-name">Period</div>
                  <input className="period-input" type="text" placeholder="ms" value={newNpnPeriod} onChange={(e) => {
                    setNewNpnPeriod(e.target.value)
                  }}></input>
                  <button disabled={true} title="" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
              </div>

              <div className="pnp-config">
                <div className="gen-config-title">0-10v Analog Input Configuration</div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Units</div>
                  <input className="period-input" type="text" value={s2Units} onChange={(e) => {
                    setS2Units(e.target.value)
                  }}></input>
                  <button disabled={true} title="This defines the units of measure" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled High</div>
                  <input className="period-input" type="text" value={s2Upper} onChange={(e) => {
                    setS2Upper((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the upper range that the input should be scaled to" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled Low</div>
                  <input className="period-input" type="text" value={s2Lower} onChange={(e) => {
                    setS2Lower((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the lower range that the input should be scaled to" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw High</div>
                  <input className="period-input" type="text" value={s2Hi} onChange={(e) => {
                    setS2Hi((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the raw upper range of the sensor" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw Low</div>
                  <input className="period-input" type="text" value={s2Lo} onChange={(e) => {
                    setS2Lo((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the raw lower range of the sensor" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
              </div>
              <div className="pnp-config">
                <div className="gen-config-title">4-20mA Analog Input Configuration</div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Units</div>
                  <input className="period-input" type="text" value={s3Units} onChange={(e) => {
                    setS3Units(e.target.value)
                  }}></input>
                  <button disabled={true} title="This defines the units of measure" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled High</div>
                  <input className="period-input" type="text" value={s3Upper} onChange={(e) => {
                    setS3Upper((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the upper range that the input should be scaled to" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled Low</div>
                  <input className="period-input" type="text" value={s3Lower} onChange={(e) => {
                    setS3Lower((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the lower range that the input should be scaled to" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw High</div>
                  <input className="period-input" type="text" value={s3Hi} onChange={(e) => {
                    setS3Hi((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the raw upper range of the sensor" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw Low</div>
                  <input className="period-input" type="text" value={s3Lo} onChange={(e) => {
                    setS3Lo((e.target.value))
                  }}></input>
                  <button disabled={true} title="This defines the raw lower range of the sensor" className="config-help-button"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
              </div>



            </div>
          </div>
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if ((Number(newPnpPeriod).toString() === 'NaN' && newPnpOption === 'accumulator') || (Number(newNpnPeriod).toString() === 'NaN' && newNpnOption === 'accumulator')) {
                setErrorMessage('Period must contain only numbers')
                setShowError(true)
              } else if ((Number(newNpnPeriod) < 50 && newNpnOption === 'accumulator') || (Number(newPnpPeriod) < 50 && newPnpOption === 'accumulator')) {
                setErrorMessage('Period must be above 50')
                setShowError(true)
              } else {
                formulaBuilder()
                let message = new Paho.Message(messageBuilder())
                message.destinationName = `sharc/${deviceId}/cmd/cfg`
                client.send(message)
                setNpnOption(newNpnOption);
                setNpnEdge(newNpnEdge);
                setNpnPeriod(newNpnPeriod);
                setNpnCount(newNpnCount);
                setPnpOption(newPnpOption);
                setPnpEdge(newPnpEdge);
                setPnpCount(newPnpCount);
                setPnpPeriod(newPnpPeriod);
                setS2Dead(news2Dead);
                setS3Dead(news3Dead);
                setS2Transform(news2Transform);
                setS3Transform(news3Transform);
                setOpenChangeConfig(false)
              }
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenChangeConfig(false)
            }}>Cancel</button></div>
          </div>
        </form>
      </div >
    </div >
  )
}