
import Power from '../../../assets/Power.png'

// This tells the user that their browser is not supported
export default function Support({ setMode }) {
  return (
    <div id="support">
      <button style={{ width: '60px', marginBottom: '20px' }} imageUrl={Power} onClick={(e) => {
        setMode(null)
      }} alt="Disconnect BLE"></button>
      <div className="support-container">
        <div className="support-title">Your browser is not currently supported!</div>
        <div className="support-body">Please refer to <a href="https://developer.mozilla.org/en-US/docs/Web/API/Bluetooth#browser_compatibility" target="_empty">Supported Browsers</a> to see a list of supported browsers</div>
      </div>
    </div>
  )
}