
import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';

// This is used to tell the SHARC to save the changes and reboot the device
export default function Save({ setOpenSave, net, brokerIP, brokerPass, brokerPort, brokerUser, clientId, deviceId, setConnected, client, mqtt }) {
  return (
    <div id="device-modal" onMouseDown={(e) => {
      setOpenSave(false)
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Saving changes will reboot the Sharc, are you sure you want to continue?</div>
          <button className="exit" onClick={(e) => {
            setOpenSave(false)
          }}>X</button>
        </div>
        {mqtt.address && <form className="modal-form" onSubmit={(e) => {
          e.preventDefault()
        }}>
          <div className="reboot-container">
            <div className="reboot-container-options">
              <div className="reboot"><button onClick={(e) => {
                let message = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": {
                    "device.save.ble" : true
                  }
                }`)
                message.destinationName = `sharc/${deviceId}/cmd/action`
                client.send(message)
                setTimeout(() => {

                  client.disconnect();
                }, 250);
                setOpenSave(false)
                setConnected(false)
              }}>Reboot into BLE</button></div>
              <div className="reboot"><button onClick={(e) => {
                let message = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": {
                    "device.save.mqtt" : true
                  }
                }`)
                message.destinationName = `sharc/${deviceId}/cmd/action`
                client.send(message)
                setTimeout(() => {

                  client.disconnect();
                }, 250);
                setOpenSave(false)
                setConnected(false)
              }}>Reboot into MQTT</button></div>
            </div>
          </div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault()
            setOpenSave(false)
          }}>Cancel</button></div>
        </form>}
        {!mqtt.address && <div className="reboot-container-options">
          <div className="reboot"><button onClick={(e) => {
            let message = new Paho.Message(`{
                                      "id": "${uuidv4()}",
                                      "v": {
                                        "cfg.save" : true
                                      }
                                    }`)
            message.destinationName = `sharc/${deviceId}/cmd/action`
            client.send(message)
            setTimeout(() => {

              client.disconnect();
            }, 250);
            setOpenSave(false)
            setConnected(false)
          }}>Save and Reboot</button></div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault()
            setOpenSave(false)
          }}>Cancel</button></div>
        </div>}
      </div>
    </div>
  )
}