
import { v4 as uuidv4 } from 'uuid';
import Paho from 'paho-mqtt';

// This is used to tell the connected SHARC to discard all unsaved changes and reboot the device
export default function Reboot({ setOpenReboot, setConnected, net, brokerIP, brokerPass, brokerPort, brokerUser, clientId, deviceId, client, mqtt }) {

  return (
    <div id="device-modal" onMouseDown={(e) => {
      setOpenReboot(false)
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div className="modal-title">Are you sure you want to reboot?</div>
            <div className="modal-warning">(Any unsaved changes will be reset!)</div>
          </div>
          <button className="exit" onClick={(e) => {
            setOpenReboot(false)
          }}>X</button>
        </div>
        {mqtt.address && <form className="modal-form" onSubmit={(e) => {
          e.preventDefault()
        }}>
          <div className="reboot-container">
            <div className="reboot-container-options">
              <div className="reboot"><button onClick={(e) => {
                let message = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": {
                    "device.reset.ble" : true
                  }
                }`)
                message.destinationName = `sharc/${deviceId}/cmd/action`
                client.send(message)
                setTimeout(() => {

                  client.disconnect()
                }, 250)
                setOpenReboot(false)
                setConnected(false)
              }}>Reboot into BLE</button></div>
              <div className="reboot"><button onClick={(e) => {
                let message = new Paho.Message(`{
                  "id": "${uuidv4()}",
                  "v": {
                    "device.reset.mqtt" : true
                  }
                }`)
                message.destinationName = `sharc/${deviceId}/cmd/action`
                client.send(message)
                setTimeout(() => {

                  client.disconnect()
                }, 250)
                setOpenReboot(false)
                setConnected(false)
              }}>Reboot into MQTT</button></div>
            </div>
          </div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault()
            setOpenReboot(false)
          }}>Cancel</button></div>
        </form>}
        {!mqtt.address && <div className="reboot-container-options">
          <div className="reboot"><button onClick={(e) => {
            let message = new Paho.Message(`{
                                      "id": "${uuidv4()}",
                                      "v": {
                                        "device.reset" : true
                                      }
                                    }`);
            message.destinationName = `sharc/${deviceId}/cmd/action`;
            client.send(message);
            setTimeout(() => {
              client.disconnect();
            }, 250);
            setOpenReboot(false);
            setConnected(false);
          }}>Reboot and Discard</button></div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault();
            setOpenReboot(false);
          }}>Cancel</button></div>
        </div>}
      </div>
    </div>
  )
}