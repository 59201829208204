import { useState, useEffect } from 'react'
// import { v4 as uuidv4 } from 'uuid';
// import Paho from 'paho-mqtt';
import Help from '../../../../assets/Help.webp'
import MQTTError from '../../../MQTT/105/MQTTError';
import { Blocks } from 'react-loader-spinner'



// This is used to change what MQTT broker the device connects to
export default function ChangeConfig({ sharcInfo, setOpenChangeConfig, saveConfig, setSharcInfo, getInfo, showError, errorMessage, setShowError, setErrorMessage, whichSharc }) {

  const [format, setFormat] = useState(sharcInfo.agg);
  const [cal, setCal] = useState(sharcInfo.calibrate);
  const [conv, setConv] = useState(sharcInfo.convert);
  const [pnpOption, setPnpOption] = useState(sharcInfo.s0Mode);
  const [pnpEdge, setPnpEdge] = useState(sharcInfo.s0Edge);
  const [pnpCount, setPnpCount] = useState(sharcInfo.s0Persist);
  const [pnpPeriod, setPnpPeriod] = useState(sharcInfo.s0Period);
  const [npnOption, setNpnOption] = useState(sharcInfo.s1Mode);
  const [npnEdge, setNpnEdge] = useState(sharcInfo.s1Edge);
  const [npnCount, setNpnCount] = useState(sharcInfo.s1Persist);
  const [npnPeriod, setNpnPeriod] = useState(sharcInfo.s1Period);
  const [s2Convert, setS2Convert] = useState(sharcInfo.s2Convert);
  const [s3Convert, setS3Convert] = useState(sharcInfo.s3Convert);
  const [s2Units, setS2Units] = useState('v');
  const [s2Upper, setS2Upper] = useState('10');
  const [s2Lower, setS2Lower] = useState('0');
  const [s2Hi, setS2Hi] = useState('10');
  const [s2Lo, setS2Lo] = useState('0');
  const [s3Units, setS3Units] = useState('mA');
  const [s3Upper, setS3Upper] = useState('20');
  const [s3Lower, setS3Lower] = useState('4');
  const [s3Hi, setS3Hi] = useState('20');
  const [s3Lo, setS3Lo] = useState('4');
  const [sensorSettingsLoading, setSensorSettingsLoading] = useState(false);

  useEffect(() => {
    if (whichSharc === '105') {
      var regexPattern = /[-+]?(\d*\.\d+|\d+)/g;
      // Extract all numeric values from the string
      setS2Units(s2Convert.split(',')[1].replace(/'/g, '').trim())
      var s2Matches = s2Convert.match(regexPattern).map(parseFloat);
      setS2Upper(s2Matches[4])
      setS2Lower(s2Matches[5])
      setS2Hi(s2Matches[2])
      setS2Lo(s2Matches[1])
      setS3Units(s3Convert.split(',')[1].replace(/'/g, '').trim())
      var s3Matches = s3Convert.match(regexPattern).map(parseFloat);
      setS3Upper(s3Matches[4])
      setS3Lower(s3Matches[5])
      setS3Hi(s3Matches[2])
      setS3Lo(s3Matches[1])
    }
  }, [s2Convert, s3Convert])


  function formulaBuilder() {
    var volt = `(float('{:.1f}'.format((v - ${Number(s2Lo)}) / (${Number(s2Hi)} - ${Number(s2Lo)}) * (${Number(s2Upper)} - ${Number(s2Lower)}) + ${Number(s2Lower)})), '${s2Units}', False)`
    var mA = `(float('{:.1f}'.format((v - ${Number(s3Lo)}) / (${Number(s3Hi)} - ${Number(s3Lo)}) * (${Number(s3Upper)} - ${Number(s3Lower)}) + ${Number(s3Lower)})), '${s3Units}', False)`
    var formulas = {
      volt,
      mA
    }
    console.log(formulas)
    return formulas

  }
  return (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setOpenChangeConfig(false)
    }}>
      {showError && <MQTTError showError={showError} errorMessage={errorMessage} setShowError={setShowError} step={'Error Changing Config'} setErrorMessage={setErrorMessage} />}
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit Sensor Configuration</div>
          <button className="exit" onClick={(e) => {
            setOpenChangeConfig(false)
          }}>X</button>
        </div>
        {sensorSettingsLoading &&
          <Blocks visible={true}
            height="150" width="150"
            ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
        }
        {!sensorSettingsLoading && <form className="modal-form">
          <div id="sensor-landing">
            <div className="sensor-landing-container">
              {whichSharc === '105' && <div className="gen-config">
                <div className="gen-config-title">General Configuration</div>
                <div className="gen-config-option">
                  <div className="gen-config-name">MQTT Payload Format</div>
                  <select className="config-dropdown" value={format} onChange={(e) => {
                    setFormat(e.target.value)
                  }}>
                    <option value={1}>Aggregation On</option>
                    <option value={0}>Aggregation Off</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines whether the MQTT payload for the sensors comes in on the same topic or seperate"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                {/* <div className="gen-config-option">
                  <div className="gen-config-name">Calibrate</div>
                  <select className="config-dropdown" value={cal} onChange={(e) => {
                    setCal(e.target.value)
                  }}>
                    <option value={1}>True</option>
                    <option value={0}>False</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines whether the sensors will use the calibrate formula or not"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Convert</div>
                  <select className="config-dropdown" value={conv} onChange={(e) => {
                    setConv(e.target.value)
                  }}>
                    <option value={1}>True</option>
                    <option value={0}>False</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines whether the sensors will use the convert formula or not"><img className="config-help" src={Help} alt="Help"></img></button>
                </div> */}
              </div>}
              <div className="pnp-config">
                <div className="gen-config-title">PNP Digital Input Configuration</div>
                {<div className="gen-config-option">
                  <div className="gen-config-name">Function</div>
                  <select className="config-dropdown" value={pnpOption} onChange={(e) => {
                    setPnpOption(e.target.value)
                  }}>
                    <option value="switch">Switch</option>
                    <option value="counter">Counter</option>
                    <option value="accumulator">Accumulator</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines what type of sensor is in use"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {(pnpOption === 'counter' || pnpOption === 'accumulator') && <div className="gen-config-option">
                  <div className="gen-config-name">Edge</div>
                  <select className="config-dropdown" value={pnpEdge} onChange={(e) => {
                    setPnpEdge(e.target.value)
                  }}>
                    <option value="any">Any</option>
                    <option value="rising">Rising</option>
                    <option value="falling">Falling</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines what edge the sensor will count on"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {pnpOption === 'accumulator' && <div className="gen-config-option">
                  <div className="gen-config-name">Period</div>
                  <input className="period-input" type="text" placeholder="ms" value={pnpPeriod} onChange={(e) => {
                    setPnpPeriod(e.target.value)
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines how long the accumulator should count for in ms"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
              </div>
              <div className="npn-config">
                <div className="gen-config-title">NPN Digital Input Configuration</div>
                {<div className="gen-config-option">
                  <div className="gen-config-name">Function</div>
                  <select className="config-dropdown" value={npnOption} onChange={(e) => {
                    setNpnOption(e.target.value)
                  }}>
                    <option value="switch">Switch</option>
                    <option value="counter">Counter</option>
                    <option value="accumulator">Accumulator</option>
                  </select>
                  <button className="config-help-button" disabled={true} title="This defines what type of sensor is in use"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {(npnOption === 'counter' || npnOption === 'accumulator') && <div className="gen-config-option">
                  <div className="gen-config-name">Edge</div>
                  <select className="config-dropdown" value={npnEdge} onChange={(e) => {
                    setNpnEdge(e.target.value)
                  }}>
                    <option value="any">Any</option>
                    <option value="rising">Rising</option>
                    <option value="falling">Falling</option>
                  </select>
                  <button className="config-help-button" disabled={true}><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
                {npnOption === 'accumulator' && <div className="gen-config-option">
                  <div className="gen-config-name">Period</div>
                  <input className="period-input" type="text" placeholder="ms" value={npnPeriod} onChange={(e) => {
                    setNpnPeriod(e.target.value)
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines how long the accumulator should count for in ms"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>}
              </div>

              <div className="pnp-config">
                <div className="gen-config-title">0-10v Analog Input Configuration</div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Units</div>
                  <input className="period-input" type="text" value={s2Units} onChange={(e) => {
                    setS2Units(e.target.value)
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the units of measure"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled High</div>
                  <input className="period-input" type="text" value={s2Upper} onChange={(e) => {
                    setS2Upper((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the upper range that the input should be scaled to"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled Low</div>
                  <input className="period-input" type="text" value={s2Lower} onChange={(e) => {
                    setS2Lower((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the lower range that the input should be scaled to"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw High</div>
                  <input className="period-input" type="text" value={s2Hi} onChange={(e) => {
                    setS2Hi((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the raw upper range of the sensor"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw Low</div>
                  <input className="period-input" type="text" value={s2Lo} onChange={(e) => {
                    setS2Lo((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the raw lower range of the sensor"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
              </div>
              <div className="pnp-config">
                <div className="gen-config-title">4-20mA Analog Input Configuration</div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Units</div>
                  <input className="period-input" type="text" value={s3Units} onChange={(e) => {
                    setS3Units(e.target.value)
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the units of measure"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled High</div>
                  <input className="period-input" type="text" value={s3Upper} onChange={(e) => {
                    setS3Upper((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the upper range that the input should be scaled to"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Scaled Low</div>
                  <input className="period-input" type="text" value={s3Lower} onChange={(e) => {
                    setS3Lower((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the lower range that the input should be scaled to"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw High</div>
                  <input className="period-input" type="text" value={s3Hi} onChange={(e) => {
                    setS3Hi((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the raw upper range of the sensor"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
                <div className="gen-config-option">
                  <div className="gen-config-name">Raw Low</div>
                  <input className="period-input" type="text" value={s3Lo} onChange={(e) => {
                    setS3Lo((e.target.value))
                  }}></input>
                  <button className="config-help-button" disabled={true} title="This defines the raw lower range of the sensor"><img className="config-help" src={Help} alt="Help"></img></button>
                </div>
              </div>



            </div>
          </div>
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if ((Number(pnpPeriod).toString() === 'NaN' && pnpOption === 'accumulator') || (Number(npnPeriod).toString() === 'NaN' && npnOption === 'accumulator')) {
                setErrorMessage('Period must contain only numbers')
                setShowError(true)
              } else if ((Number(npnPeriod) < 50 && npnOption === 'accumulator') || (Number(pnpPeriod) < 50 && pnpOption === 'accumulator')) {
                setErrorMessage('Period must be above 50')
                setShowError(true)
              } else {
                setSensorSettingsLoading(true)
                if (whichSharc === '105') {
                  saveConfig(format, pnpOption, pnpEdge, pnpCount, pnpPeriod, npnOption, npnEdge, npnCount, npnPeriod, formulaBuilder().volt, formulaBuilder().mA, conv, cal)
                    .then(() => {
                      getInfo()
                        .then((res) => {
                          setOpenChangeConfig(false)
                          let update = {
                            name: res[0],
                            model: res[1],
                            serial: res[2],
                            hardware: res[3],
                            firmware: res[4],
                            software: res[5],
                            staticIp: res[6],
                            ip: res[7],
                            sub: res[8],
                            gateway: res[9],
                            dns: res[10],
                            mac: res[11],
                            brokerIP: res[12],
                            brokerPort: res[13],
                            brokerUser: res[14],
                            brokerPass: res[15],
                            agg: res[16],
                            calibrate: res[17],
                            convert: res[18],
                            s0Mode: res[19],
                            s0Edge: res[20],
                            s0Persist: res[21],
                            s0Period: res[22],
                            s0Calibrate: res[23],
                            s0Convert: res[24],
                            s1Mode: res[25],
                            s1Edge: res[26],
                            s1Persist: res[27],
                            s1Period: res[28],
                            s1Calibrate: res[29],
                            s1Convert: res[30],
                            s2Deadband: res[31],
                            s2Calibrate: res[32],
                            s2Convert: res[33],
                            s3Deadband: res[34],
                            s3Calibrate: res[35],
                            s3Convert: res[36],
                            wlanSSID: res[42],
                            wlanPass: res[43],
                            wlanFallback: res[44],
                            connectionType: res[45],
                            connectionQuality: res[46],
                            adoptionKey: res[47]
                          }
                          setSharcInfo(sharcInfo => ({
                            ...sharcInfo,
                            ...update
                          }))
                          setSensorSettingsLoading(false)
                        })
                    })
                } else {

                }
              }
            }}>Update</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setOpenChangeConfig(false)
            }}>Cancel</button></div>
          </div>
        </form>}
      </div >
    </div >
  )
}