import { useEffect } from "react"
import MQTTError from "../../MQTT/105/MQTTError"

// This is used to search for sharc devices
export default function Connect({ connected, setConnected, sharcInfo, setSharcInfo, getInfo, findDevice, getData, disconnect, changeNetConfig, changeMqttConfig, rebootDevice, validateIPaddress, validateSubnet, validatePort, setMode, showError, errorMessage, setShowError, mode, setErrorMessage }) {



  return (
    <div id="connect">
      {showError && <MQTTError showError={showError} errorMessage={errorMessage} setShowError={setShowError} step={'Bluetooth Connection Error'} setErrorMessage={setErrorMessage} />}
      <div className="connect-container">
        <div className="connect-desc">Then press the CONNECT button below to create a remote conection to your nearby SHARC</div>
        <button className="connect-btn" onClick={(e) => {
          // Find the device, once found set connected to true
          findDevice()
            .then((res) => {
              return res !== undefined ? setConnected(true) : setConnected(false)
            })
            .then(() => {
              getInfo()
                // Get relevant information and update sharc info with that information
                .then((res) => {
                  let update = {
                    name: res[0],
                    model: res[1],
                    serial: res[2],
                    hardware: res[3],
                    firmware: res[4],
                    software: res[5],
                    staticIp: res[6],
                    ip: res[7],
                    sub: res[8],
                    gateway: res[9],
                    dns: res[10],
                    mac: res[11],
                    brokerIP: res[12],
                    brokerPort: res[13],
                    brokerUser: res[14],
                    brokerPass: res[15],
                    agg: res[16],
                    calibrate: res[17],
                    convert: res[18],
                    s0Mode: res[19],
                    s0Edge: res[20],
                    s0Persist: res[21],
                    s0Period: res[22],
                    s0Calibrate: res[23],
                    s0Convert: res[24],
                    s1Mode: res[25],
                    s1Edge: res[26],
                    s1Persist: res[27],
                    s1Period: res[28],
                    s1Calibrate: res[29],
                    s1Convert: res[30],
                    s2Deadband: res[31],
                    s2Calibrate: res[32],
                    s2Convert: res[33],
                    s3Deadband: res[34],
                    s3Calibrate: res[35],
                    s3Convert: res[36],
                    wlanSSID: res[42],
                    wlanPass: res[43],
                    wlanFallback: res[44],
                    connectionType: res[45],
                    connectionQuality: res[46],
                    adoptionKey: res[47]

                  }
                  setSharcInfo(sharcInfo => ({
                    ...sharcInfo,
                    ...update
                  }))
                })
            })

        }} >Connect</button>
      </div>
      <button onClick={() => {
        setMode(null)
      }} className="go-back" >Go Back</button>
    </div >
  )
}

