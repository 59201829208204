import { useState } from "react"
export default function Upload({ setSelectedTab, updateFirmware, sendFileToSharc, readFile }) {
    const [firmwareLink, setFirmwareLink] = useState('');
    const [erroredOut, setErroredOut] = useState(false);
    const [showFirmware, setShowFirmware] = useState(false)
    const [showUploadCert, setShowUploadCert] = useState(false)
    const [showUploadPK, setShowUploadPK] = useState(false)
    return (
        <div id="device-modal" onMouseDown={(e) => {
            setSelectedTab('info')
        }}>
            <div className="modal-container" onMouseDown={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-header">
                    <button className="exit hidden">X</button>
                    <div className="modal-title">Select Option</div>
                    <button className="exit" onClick={(e) => {
                        // setOpenDevice(false)
                        setSelectedTab('info')

                    }}>X</button>
                </div>
                <div className="button-container">
                    <div className="save">
                        <button onClick={(e) => {
                            e.preventDefault()
                            setShowFirmware(true)
                        }}>Update Firmware</button>
                    </div>
                    <div className="save">
                        <button onClick={(e) => {
                            e.preventDefault()
                            setShowUploadCert(true)
                        }}>Upload Certificate</button>
                    </div>
                    <div className="save">
                        <button onClick={(e) => {
                            e.preventDefault()
                            setShowUploadPK(true)
                        }}>Upload Private Key</button>
                    </div>
                </div>
                <div className="reboot-container">
                    <div className="reboot cancel"><button onClick={(e) => {
                        setSelectedTab('info')
                        setShowFirmware(false)
                    }}>Cancel</button></div>
                </div>
                {showFirmware && <UploadFirmware setSelectedTab={setSelectedTab} updateFirmware={updateFirmware} setShowFirmware={setShowFirmware} />}
                {showUploadCert && <UploadCert setSelectedTab={setSelectedTab} setShowUploadCert={setShowUploadCert} sendFileToSharc={sendFileToSharc} readFile={readFile} />}
                {showUploadPK && <UploadPK setSelectedTab={setSelectedTab} setShowUploadPK={setShowUploadPK} sendFileToSharc={sendFileToSharc} readFile={readFile} />}
            </div>
        </div>
    )
}

function UploadFirmware({ setSelectedTab, updateFirmware, setShowFirmware }) {
    const [firmwareLink, setFirmwareLink] = useState('');
    const [erroredOut, setErroredOut] = useState(false);
    return (


        <div id="device-modal" onMouseDown={(e) => {
            setSelectedTab('info')
            setShowFirmware(false)
        }}>
            <div className="modal-container" onMouseDown={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-header">
                    <button className="exit hidden">X</button>
                    <div className="modal-title">Upload Firmware</div>
                    <button className="exit" onClick={(e) => {
                        // setOpenDevice(false)
                        setSelectedTab('info')
                        setShowFirmware(false)

                    }}>X</button>
                </div>
                <form className="modal-form" onSubmit={(e) => {
                    e.preventDefault()
                }}>
                    <div>
                        <div style={{ fontWeight: 'bold', fontSize: 20 }}>Please Carefully Follow the Firmware Instructions</div>
                        <a href="https://github.com/SharcTech/sharc-support/tree/main/firmware" target="_blank" style={{ fontWeight: 'bold', fontSize: 20, textDecoration: 'underline', color: 'blue', margin: '10px 0', display: 'block' }}>Instructions</a>
                    </div>
                    <div>
                        <label>Link To Firmware Update: <input type='text' value={firmwareLink} onChange={(e) => {
                            setFirmwareLink(e.target.value)
                        }}></input></label>
                    </div>
                    {erroredOut && <div>
                        <div style={{ fontWeight: 'bold', fontSize: 30, color: 'red' }}>Please Upload a Valid SHARC BIN File</div>
                    </div>}
                    <div className="reboot-container">
                        <div className="save"><button onClick={(e) => {
                            if (updateFirmware(firmwareLink) === 'done') {
                                setErroredOut(false)
                                setShowFirmware(false)
                                // setSelectedTab('info')
                                // setShowFirmware(false)

                            } else {
                                setErroredOut(true)
                                setFirmwareLink('')
                            }
                        }}>Update</button></div>
                        <div className="reboot cancel"><button onClick={(e) => {
                            // setSelectedTab('info')
                            setShowFirmware(false)
                        }}>Go Back</button></div>
                    </div>
                </form>
            </div>
        </div>
    )
}
function UploadCert({ setShowUploadCert, readFile, sendFileToSharc, setSelectedTab }) {
    const [fileSelected, setFileSelected] = useState('');
    return (
        <div id="device-modal" onMouseDown={(e) => {
            setSelectedTab('info')
            setShowUploadCert(false)
            setFileSelected('')
        }}>
            <div className="modal-container" onMouseDown={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-header">
                    <button className="exit hidden">X</button>
                    <div className="modal-title">Upload Certificate</div>
                    <button className="exit" onClick={(e) => {
                        // setOpenDevice(false)
                        setSelectedTab('info')
                        setShowUploadCert(false)
                        setFileSelected('')

                    }}>X</button>
                </div>
                <form className="modal-form" onSubmit={(e) => {
                    e.preventDefault()
                }}>
                    <div className="button-container">
                        <input
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    setFileSelected(file);
                                }
                            }}
                        />
                    </div>
                    <div className="reboot-container">
                        <div className="save"><button onClick={async (e) => {
                            e.preventDefault()
                            let file = await readFile(fileSelected)
                            await sendFileToSharc(file, 'cert')
                            setFileSelected('')
                            setShowUploadCert(false)
                        }}>Update</button></div>
                        <div className="reboot cancel"><button onClick={(e) => {
                            setShowUploadCert(false)
                            setFileSelected('')
                        }}>Go Back</button></div>
                    </div>
                </form>
            </div >
        </div >
    )
}
function UploadPK({ setShowUploadPK, readFile, sendFileToSharc, setSelectedTab }) {
    const [fileSelected, setFileSelected] = useState('');
    return (
        <div id="device-modal" onMouseDown={(e) => {
            setSelectedTab('info')
            setShowUploadPK(false)
            setFileSelected('')
        }}>
            <div className="modal-container" onMouseDown={(e) => {
                e.stopPropagation()
            }}>
                <div className="modal-header">
                    <button className="exit hidden">X</button>
                    <div className="modal-title">Upload Private Key</div>
                    <button className="exit" onClick={(e) => {
                        // setOpenDevice(false)
                        setSelectedTab('info')
                        setShowUploadPK(false)
                        setFileSelected('')

                    }}>X</button>
                </div>
                <form className="modal-form" onSubmit={(e) => {
                    e.preventDefault()
                }}>
                    <div className="button-container">
                        <input
                            type="file"
                            onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                    setFileSelected(file);
                                }
                            }}
                        />
                    </div>
                    <div className="reboot-container">
                        <div className="save"><button onClick={async (e) => {
                            e.preventDefault()
                            let file = await readFile(fileSelected)
                            await sendFileToSharc(file, 'pk')
                            setFileSelected('')
                            setShowUploadPK(false)
                        }}>Update</button></div>
                        <div className="reboot cancel"><button onClick={(e) => {
                            setShowUploadPK(false)
                            setFileSelected('')
                        }}>Go Back</button></div>
                    </div>
                </form>
            </div >
        </div >
    )
}