
import { useState, useEffect } from 'react'
import { options } from './ExecuteJSON'
import Paho from 'paho-mqtt';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

// This is used to execute arbitrary code on the device that is supplied by the user
export default function Execute({ deviceId, brokerIP, brokerPort, brokerUser, brokerPass, clientId, setMessages, messages, description, setDescription, destination, setDestination, destinationShort, setDestinationShort, setConnected, client, loggedIn, setLoggedIn, setSelectedTab }) {

  const [editorData, setEditorData] = useState('');
  const [pinEntry, setPinEntry] = useState('');

  // On load or new messages verify that there is not more than 50 messages in the array, if there is then start to remove messages
  useEffect(() => {
    if (messages.length > 50) {
      messages.unshift()
    }
  }, [messages])

  return loggedIn ? (
    <div id="execute">
      <div className="execute-container">
        <div className="execute-options">
          <div className="execute-options-title">Payload Selection</div>
          <div className="execute-options-container">
            {/* For each option create a button with that payload's info */}
            {options.map((option, i) => {
              return (
                <button onClick={(e) => {
                  e.preventDefault()
                  setEditorData(JSON.stringify(option.payload, null, 4))
                  setDescription(option.description)
                  setDestination(`sharc/${deviceId}/${option.destination}`)
                  setDestinationShort(option.destination)
                }} key={i} className="option-button">{option.description}</button>
              )
            })}
          </div>
        </div>
        <div className="execute-editing-container">
          <div className="execute-editing-header">
            <button rounded={"large"} onClick={(e) => {
              e.preventDefault()
              let message = new Paho.Message(editorData)
              message.destinationName = destination
              client.send(message)
            }} className="option-run">Run</button>
            <div className="editing-header-values-container">
              <div className="execute-editing-header-container">
                <div className="execute-editing-button-name">Description:</div>
                <div className="execute-editing-button-value">{description}</div>
              </div>
              <div className="execute-editing-header-container">
                <div className="execute-editing-button-name">Destination:</div>
                <div className="execute-editing-button-value">{destination}</div>
              </div>
            </div>
          </div>
          <div className="execute-editor">
            <textarea value={editorData} type="text" onChange={(e) => {
              setEditorData(e.target.value)
            }} style={{ width: '98%', height: '100%' }}></textarea>
          </div>
        </div>
        <div className="execute-messages-container">
          <div className="execute-messages-name">Messages View</div>
          <div className="execute-messages-list">
            {messages.slice().reverse().map((message, i) => {
              return (
                <div className="message-container" key={i}>
                  <div className="message-container-header">
                    <div className="message-topic">{message.destinationNameShort}</div>
                    <div className="message-timestamp">{message.timeStamp}</div>
                  </div>
                  <div className="message-payload"><JSONPretty data={JSON.parse(message.payloadString)}></JSONPretty></div>
                  <div className="message-direction">{message.direction}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setSelectedTab('info')
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Enter PIN to access Execute page</div>
          <button className="exit" onClick={(e) => {
            setSelectedTab('info')
          }}>X</button>
        </div>
        <form className="modal-form">
          <input type='password' value={pinEntry} onChange={(e) => {
            e.preventDefault()
            setPinEntry(e.target.value)
          }} maxLength="4" autoFocus></input>
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if (pinEntry === '0000') {
                setLoggedIn(true)
              } else {
                alert('Incorrect PIN!')
                setPinEntry('')
              }
            }}>Enter</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setSelectedTab('info')

            }}>Cancel</button></div>
          </div>
        </form>
      </div>
    </div>

  )
}