import Sine from '../../../assets/Sine.png'
import Info from '../../../assets/InfoIcon.png'
import Logo from '../../../assets/Logo.png'
import Power from '../../../assets/Power.png'
import Execute from '../../../assets/Execute.png'
import Upload from '../../../assets/Upload.png'
// import Config from '../../assets/Config.png'
import DropDown from '../../../assets/DropDown.png'
import Reboot from './Settings/Reboot'
import Save from './Settings/Save'
import Help from '../../../assets/Help.webp'

import { useState } from 'react'

// This shows the logo, the current SHARC connected and the pages as selectable tabs
export default function InfoHeader({ setSelectedTab, selectedTab, setMode, setConnected, deviceId, dropDown, setDropDown, brokerIP, brokerPort, brokerPass, brokerUser, clientId, net, client, mqtt }) {

  const [openReboot, setOpenReboot] = useState(false);
  const [openSave, setOpenSave] = useState(false);

  return (
    <div id="info-header">

      {/* If the reboot button has been clicked */}
      {openReboot && <Reboot setOpenReboot={setOpenReboot} brokerIP={brokerIP} brokerPort={brokerPort} brokerUser={brokerUser} brokerPass={brokerPass} clientId={clientId} net={net} deviceId={deviceId} setConnected={setConnected} client={client} mqtt={mqtt} />}

      {/* If the save and reboot button has been clicked */}
      {openSave && <Save setOpenSave={setOpenSave} brokerIP={brokerIP} brokerPort={brokerPort} brokerUser={brokerUser} brokerPass={brokerPass} clientId={clientId} net={net} deviceId={deviceId} setConnected={setConnected} client={client} mqtt={mqtt} />}

      <div className="info-header-container">
        <div style={{ padding: 0 }} className="logo">
          <a href="/"><img id="logo" src={Logo} alt="Sharc" style={{ width: '450px', boxSizing: 'borderBox' }} ></img></a>
        </div>
        <div style={{ padding: 0 }} className="logo">
          <div className="device-name-container"><div className="device-name-title">Connected Sharc </div><div className="device-name">{deviceId}</div></div>
        </div>

        <div className="options">
          <button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'info' ? '0 0 0 2px #24a0ed' : null }} onClick={(e) => {
            setSelectedTab('info')
          }}><img className="header-buttons" src={Info} alt="Info"></img></button>

          <button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'charts' ? '0 0 0 2px #24a0ed' : null }} onClick={(e) => {
            setSelectedTab('charts')
          }}><img className="header-buttons" src={Sine} alt="Sine"></img></button>
          <button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'execute' ? '0 0 0 2px #24a0ed' : null }} onClick={(e) => {
            setSelectedTab('execute')
          }}><img className="header-buttons" src={Execute} alt="Execute"></img></button>
          <a href="https://github.com/SharcTech/sharc-support" target="_blank" rel="noreferrer"><button className="header-buttons-container" style={{ width: '60px', boxShadow: selectedTab === 'help' ? '0 0 0 2px #24a0ed' : null }}><img className="header-buttons" src={Help} alt="Help"></img></button></a>
          <div>
            <div className="drop-down-container">
              <button className="header-buttons-container" style={{ width: '60px', borderRight: '1px solid black', borderRadius: '0px' }} onClick={(e) => {
                setDropDown(false)
                setOpenSave(true)
              }} alt="Disconnect MQTT"><img className="header-buttons power" src={Power} alt="Power"></img></button>
              <button className="drop-down-button" onClick={(e) => {
                setDropDown(!dropDown)
              }}>
                <img src={DropDown} style={{ height: '10px', justifyContent: 'flex-end', display: 'flex' }} alt="Arrow"></img>
              </button>
            </div>

            {/* If the dropdown arrow has been clicked, show the power options */}
            {dropDown === true &&
              <div className="options-drop-down">
                <button className="drop-down-option" onClick={() => {
                  setDropDown(false)
                  setOpenSave(true)
                }}>Save and Reboot</button>
                <button className="drop-down-option" onClick={() => {
                  setDropDown(false)
                  setOpenReboot(true)
                }}>Discard and Reboot</button>
                <button className="drop-down-option" onClick={() => {
                  client.disconnect();
                  setDropDown(false)
                  setMode(null)
                  setConnected(false)
                }}>Disconnect</button>
              </div>
            }
          </div>
          {mqtt.address && <button className="header-buttons-container" style={{ width: '60px' }} onClick={(e) => {
            setSelectedTab('upload')
          }}><img className="header-buttons" src={Upload} alt="Upload"></img></button>}
        </div>
      </div>
    </div>
  )
}