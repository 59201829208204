import Paho from 'paho-mqtt';
import MQTTError from './MQTTError';
import { useEffect } from 'react';

// Create Unique Client ID
const clientId = `mqtt_${Math.random().toString(16).slice(3)}`;

// Used to edit the MQTT Config that the browser gets the information from !! NOT THE DEVICE !!
export default function MQTTConfig({ setMode, validateIPaddress, validatePort, brokerIP, setBrokerIP, brokerPort, setBrokerPort, brokerUser, setBrokerUser, brokerPass, setBrokerPass, setConnected, setDeviceID, deviceID, setClient, useSSL, setUseSSL, showError, errorMessage, setShowError, mode, setErrorMessage }) {

  return (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setMode(null)
    }}>

      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        {showError && <MQTTError setShowError={setShowError} errorMessage={errorMessage} step={'Error Connecting to MQTT'} setErrorMessage={setErrorMessage} />}
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Edit MQTT Configuration</div>
          <button className="exit" onClick={(e) => {
            setMode(null)
          }}>X</button>
        </div>
        <form className="modal-form">
          <div className="form-container">
            <label>Broker IP: <input type='text' value={brokerIP} onChange={(e) => {
              setBrokerIP(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Port: <input type='text' value={brokerPort} onChange={(e) => {
              setBrokerPort(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Username: <input type='username' value={brokerUser} onChange={(e) => {
              setBrokerUser(e.target.value)
            }}></input></label>
          </div>
          <div className="form-container">
            <label>Broker Password: <input type='password' value={brokerPass} onChange={(e) => {
              setBrokerPass(e.target.value)
            }}></input></label>
          </div>
          <label>Use SSL: <input type='checkbox' checked={useSSL} onChange={(e) => {
            setUseSSL(e.target.checked)
          }}></input></label>

          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if (validateIPaddress(brokerIP) && validatePort(brokerPort)) {
                setClient(new Paho.Client(brokerIP, Number(brokerPort), clientId))
                setConnected(true)
              }
            }}>Save Settings</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              setMode(null)
            }}>Go Back</button></div>
          </div>

        </form>
        <div >(MQTT Broker must have WebSockets enabled.)</div>
      </div>
    </div>
  )
}