import { useState } from 'react'

// This is used to display the available SHARCS connected to the MQTT broker
export default function Devices({ getDeviceInfo, devices, setOpenDevicesTab, setMode, setConnected }) {

  const [value, setValue] = useState('')

  // If there are avaiable devices
  return devices.length > 0 ? (
    <div id="mqtt-modal" onMouseDown={(e) => {
      setOpenDevicesTab(false)
      setMode(null)

    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">Please Select Your SHARC</div>
          <button className="exit" onClick={(e) => {
            setOpenDevicesTab(false)
            setMode(null)

          }}>X</button>
        </div>
        <form>
          <select onChange={(e) => {
            setValue(e.target.value)
          }} className="device-dropdown" value={value}>
            <option></option>
            {devices.map((item, i) => {
              return (
                <option key={i} className="drop-down">{item}</option>
              )
            })}
          </select>
          <div className='reboot-container'>
            <div className="save"><button onClick={(e) => {
              e.preventDefault()
              if (value !== '') {
                getDeviceInfo(value)
                setOpenDevicesTab(false)
              } else {
                console.log('No Sharc Selected')
              }
            }}>Select</button></div>
            <div className="reboot cancel"><button onClick={(e) => {
              e.preventDefault()
              setOpenDevicesTab(false)
              setMode(null)
            }}>Cancel</button></div>
          </div>
        </form>
      </div>
    </div>
  ) : (
    // If no devices available
    <div id="mqtt-modal" onMouseDown={(e) => {
      setConnected(false)
      setOpenDevicesTab(false)
      setMode(null)

    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation()
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div className="modal-title">No Devices Found!</div>
          <button className="exit" onClick={(e) => {
            setConnected(false)

            setOpenDevicesTab(false)
            setMode(null)
          }}>X</button>
        </div>
        <div style={{ padding: '10px' }}>Please check your settings to make sure you are connected correctly.</div>
        <form>
          <div className='reboot-container'>
            <div className="reboot cancel"><button onClick={(e) => {
              setConnected(false)
              setOpenDevicesTab(false)
              setMode(null)
            }}>Cancel</button></div>
          </div>
        </form>
      </div>
    </div>)
}