
import Logo from '../../../assets/Logo.png'

// Header
export default function Header({ connected }) {
  return navigator?.bluetooth ? (
    <div id="header">
      <div className="header-container">
        <div style={{ padding: 0 }}><a href="/"><img id="logo" src={Logo} alt='Sharc' style={{ width: '450px', boxSizing: 'borderBox' }}></img></a></div>
      </div>
    </div>
  ) : (
    <div id="header">
      <div className="header-container-no-ble">
        <div style={{ padding: 0 }}><img id="logo" src={Logo} alt='Sharc' style={{ width: '450px', boxSizing: 'borderBox', margin: '0 auto' }}></img></div>
      </div>
    </div>)
}