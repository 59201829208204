import { useState, useEffect } from 'react';
import DeviceSettings from './Settings/DeviceSettings'
import ChangeMQTT from './Settings/ChangeMQTT';
import EditIcon from '../../../assets/EditIcon.png'
import ChangeConfig from './Settings/MQTTSensorConfig'
import ChangeModbus from './Settings/ModbusConfig'
import MQTTError from './MQTTError';

import { Blocks } from 'react-loader-spinner'

// Displays information about the connected device and allows for editing of settings
export default function Information({ net, rc, ver, clientId, deviceId, setConnected, client, config, validateIPaddress, validatePort, errorMessage, showError, setShowError, mode, setErrorMessage, mqtt, rtu }) {

  const [openChangeMQTT, setOpenChangeMQTT] = useState(false);
  const [openDevice, setOpenDevice] = useState(false);
  const [openChangeConfig, setOpenChangeConfig] = useState(false);
  const [openChangeModbus, setOpenChangeModbus] = useState(false);
  const [staticIp, setStaticIp] = useState(net.static);
  const [ip, setIp] = useState('');
  const [ssid, setSSID] = useState('');
  const [lanFallbackS, setLanFallbackS] = useState(0)
  const [originalConnectionType, setOriginalConnectionType] = useState('');
  const [hasOutDatedInfo, setHasOutDatedInfo] = useState(false)
  const [connectionType, setConnectionType] = useState('');
  const [connectionQual, setConnectionQual] = useState('');
  const [port, setPort] = useState('');
  const [subnet, setSubnet] = useState('');
  const [gateway, setGateway] = useState('');
  const [dns, setDNS] = useState('');
  const [mqttUser, setMQTTUser] = useState('');
  const [mqttAddress, setMQTTAddress] = useState('');
  const [mqttPort, setMQTTPort] = useState('');
  const [mqttPass, setMQTTPass] = useState('');
  const [npnOption, setNpnOption] = useState('');
  const [npnEdge, setNpnEdge] = useState('');
  const [npnPeriod, setNpnPeriod] = useState('');
  const [npnCount, setNpnCount] = useState('');
  const [pnpOption, setPnpOption] = useState('');
  const [pnpEdge, setPnpEdge] = useState('');
  const [pnpCount, setPnpCount] = useState('');
  const [pnpPeriod, setPnpPeriod] = useState('');
  const [s2Dead, setS2Dead] = useState('');
  const [s2Transform, setS2Transform] = useState('');
  const [s3Dead, setS3Dead] = useState('');
  const [s3Transform, setS3Transform] = useState('');
  const [registers, setRegisters] = useState({});
  const [status, setStatus] = useState({});
  const [setup, setSetup] = useState({});


  // Once the page has the Network information from the connected device, set relevant information
  useEffect(() => {
    setStaticIp(net.static ? 1 : 0)
    setIp(net.ip)
    setSSID(net.ssid)
    setSubnet(net.mask)
    setGateway(net.gw)
    setDNS(net.dns)
    setPort(net.port)
    setConnectionQual(net.quality)
    setConnectionType(net.type)
    setMQTTUser(mqtt.user)
    setMQTTAddress(mqtt.address ? mqtt.address : "N/A")
    setMQTTPort(mqtt.port ? mqtt.port : "N/A")
    setOriginalConnectionType(net.type)
  }, [net, mqtt])

  useEffect(() => {
    if (JSON.stringify(rtu) !== '{}') {
      setRegisters(rtu.registers)
      setStatus(rtu.status)
      setSetup(rtu.setup)
    }
  }, [rtu])

  useEffect(() => {
    if (JSON.stringify(config) !== '{}') {
      setPnpOption(config.s0.mode)
      setPnpEdge(config.s0.edge)
      setPnpCount(config.s0.persist)
      setPnpPeriod(config.s0.period)
      setNpnOption(config.s1.mode)
      setNpnEdge(config.s1.edge)
      setNpnCount(config.s1.persist)
      setNpnPeriod(config.s1.period)
      setS2Dead(config.s2.deadband)
      setS2Transform(config.s2.transform)
      setS3Dead(config.s3.deadband)
      setS3Transform(config.s3.transform)
    }
  }, [config])

  // If net is not empty then display information page
  return (JSON.stringify(net) !== '{}') ? (
    <div className="device-info-settings">
      <div id="device-info">

        {/* If the network settings box has been clicked */}
        {openDevice && <DeviceSettings setOpenDevice={setOpenDevice} net={net} clientId={clientId} deviceId={deviceId} staticIp={staticIp} setStaticIp={setStaticIp} ip={ip} setIp={setIp} subnet={subnet} setSubnet={setSubnet} gateway={gateway} setGateway={setGateway} dns={dns} setDNS={setDNS} setConnected={setConnected} client={client} setConnectionType={setConnectionType} connectionType={connectionType} ssid={ssid} setSSID={setSSID} originalConnectionType={originalConnectionType} setOriginalConnectionType={setOriginalConnectionType} setHasOutDatedInfo={setHasOutDatedInfo} lanFallbackS={lanFallbackS} setLanFallbackS={setLanFallbackS} />}

        {/* If the MQTT settings box has been clicked */}
        {openChangeMQTT && <ChangeMQTT setOpenChangeMQTT={setOpenChangeMQTT} net={net} clientId={clientId} deviceId={deviceId} setConnected={setConnected} client={client} validateIPaddress={validateIPaddress} validatePort={validatePort} errorMessage={errorMessage} showError={showError} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} mqttUser={mqttUser} mqttAddress={mqttAddress} mqttPort={mqttPort} setMQTTUser={setMQTTUser} setMQTTAddress={setMQTTAddress} setMQTTPort={setMQTTPort} setMQTTPass={setMQTTPass} mqttPass={mqttPass} />}


        {openChangeConfig && <ChangeConfig setOpenChangeConfig={setOpenChangeConfig} deviceId={deviceId} client={client} npnOption={npnOption} npnEdge={npnEdge} npnPeriod={npnPeriod} npnCount={npnCount} pnpOption={pnpOption} pnpEdge={pnpEdge} pnpCount={pnpCount} pnpPeriod={pnpPeriod} s2Dead={s2Dead} s3Dead={s3Dead} setNpnOption={setNpnOption} setNpnEdge={setNpnEdge} setNpnPeriod={setNpnPeriod} setNpnCount={setNpnCount} setPnpOption={setPnpOption} setPnpEdge={setPnpEdge} setPnpCount={setPnpCount} setPnpPeriod={setPnpPeriod} setS2Dead={setS2Dead} setS3Dead={setS3Dead} errorMessage={errorMessage} showError={showError} setShowError={setShowError} mode={mode} setErrorMessage={setErrorMessage} s2Transform={s2Transform} setS2Transform={setS2Transform} s3Transform={s3Transform} setS3Transform={setS3Transform}
        />}
        {openChangeModbus && <ChangeModbus openChangeModbus={openChangeModbus} setOpenChangeModbus={setOpenChangeModbus} rtu={rtu} registers={registers} setRegisters={setRegisters} client={client} deviceId={deviceId} />}



        <div className="device-info-main">
          <div className="device-info-title">Device Information</div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">Device Name: </div> <div className="device-info-desc">{deviceId}</div></div>
            <div className="device-info-information"><div className="device-info-name">Manufacturer: </div> <div className="device-info-desc">{ver.mfg}</div></div>
            <div className="device-info-information"><div className="device-info-name">Model: </div> <div className="device-info-desc">{ver.model}</div></div>
            <div className="device-info-information"><div className="device-info-name">Serial Number: </div> <div className="device-info-desc">{ver.serial}</div></div>
            <div className="device-info-information"><div className="device-info-name">Hardware Revision: </div> <div className="device-info-desc">{ver.hw}</div></div>
            <div className="device-info-information"><div className="device-info-name">Firmware Revision: </div> <div className="device-info-desc">{ver.fw}</div></div>
            <div className="device-info-information"><div className="device-info-name">Software Revision: </div> <div className="device-info-desc">{ver.sw}</div></div>
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenDevice(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">Network Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            {connectionType && <div className="device-info-information"><div className="device-info-name">Connection Type:</div><div className="device-info-desc">{connectionType}</div></div>}
            {!hasOutDatedInfo && <><div className="device-info-information"><div className="device-info-name">MAC Address:</div><div className="device-info-desc">{net.mac}</div></div>
              {net && <div className="device-info-information"><div className="device-info-name">IP Mode:</div><div className="device-info-desc">{staticIp === 1 ? 'Static' : 'DHCP'}</div></div>}
              {connectionQual && <div className="device-info-information"><div className="device-info-name">Connection Quality:</div><div className="device-info-desc">{connectionQual}</div></div>}
              <div className="device-info-information"><div className="device-info-name">Device IP Address:</div><div className="device-info-desc">{ip}</div></div>
              <div className="device-info-information"><div className="device-info-name">Network Mask:</div><div className="device-info-desc">{subnet}</div></div>
              <div className="device-info-information"><div className="device-info-name">Gateway IP Address:</div><div className="device-info-desc">{gateway}</div></div>
              <div className="device-info-information"><div className="device-info-name">DNS Server IP Address:</div><div className="device-info-desc">{dns}</div></div></>}
            {hasOutDatedInfo && <>
              <div className="device-info-information" style={{ justifyContent: 'center', marginTop: 30, color: "#cc0000" }}><div className="device-info-name">Connection Type Has Changed</div></div>
              <div className="device-info-information" style={{ justifyContent: 'center', color: "#cc0000" }}><div className="device-info-name">Save and Restart to See New Network Information</div></div>
            </>}
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenChangeMQTT(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">MQTT Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">Broker IP:</div><div className="device-info-desc">{mqttAddress}</div></div>
            <div className="device-info-information"><div className="device-info-name">Broker Port:</div><div className="device-info-desc">{mqttPort}</div></div>
            <div className="device-info-information"><div className="device-info-name">Broker User:</div><div className="device-info-desc">{mqttUser}</div></div>
            {mqttAddress === 'N/A' && <div className="device-info-information" style={{ justifyContent: 'center', marginTop: 20, color: "#cc0000" }}>Cannot Display SHARC's Broker Info</div>}
            {mqttAddress === 'N/A' && <div className="device-info-information" style={{ justifyContent: 'center', color: "#cc0000" }}>Please Update Firmware</div>}
            {mqttAddress === 'N/A' && <div className="device-info-information" style={{ justifyContent: 'center', color: "#cc0000" }}>Changes WILL Still Take Effect</div>}
          </div>


        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenChangeConfig(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">Sensor Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">PNP Digital Input</div><div className="device-info-desc">{pnpOption[0]?.toUpperCase() + pnpOption?.substring(1)}</div></div>
            <div className="device-info-information"><div className="device-info-name">NPN Digital Input</div><div className="device-info-desc">{npnOption[0]?.toUpperCase() + npnOption?.substring(1)}</div></div>
          </div>
        </div>
        <div className="device-info-net" onClick={(e) => {
          e.preventDefault()
          setOpenChangeModbus(true)
        }}>
          <div className="edit-title-container">
            <div className="device-info-title">Modbus Information</div>
            <img src={EditIcon} className="edit-icon" alt="Edit"></img>
          </div>
          <div className="device-info-container">
            <div className="device-info-information"><div className="device-info-name">Number of Registers</div><div className="device-info-desc">{Object.keys(registers).length}</div></div>
            <div className="device-info-information"><div className="device-info-name">BAUD Rate</div><div className="device-info-desc">{setup.baud_rate}</div></div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    // If net is empty then show the loader
    <div className="loading">
      <Blocks visible={true} height="150" width="150" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" />
    </div>
  )
}