/* eslint-disable react/react-in-jsx-scope */

// Characteristics to reboot device
var rebootBLE = '00000002-0002-1337-1337-000000000005';
var rebootMQTT = '00000004-0002-1337-1337-000000000005';



// This is used to discard unsaved changes and reboot the device
export default function Reboot({ setOpenReboot, setConnected, rebootDevice }) {

  return (
    <div id="device-modal" onMouseDown={() => {
      setOpenReboot(false);
    }}>
      <div className="modal-container" onMouseDown={(e) => {
        e.stopPropagation();
      }}>
        <div className="modal-header">
          <button className="exit hidden">X</button>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <div className="modal-title">Are you sure you want to reboot?</div>
            <div className="modal-warning">(Any unsaved changes will be reset!)</div>
          </div>
          <button className="exit" onClick={() => {
            setOpenReboot(false);
          }}>X</button>
        </div>
        <div className="reboot-container-options">
          <div className="reboot"><button onClick={(e) => {
            e.preventDefault();
            rebootDevice(rebootBLE);
            setOpenReboot(false);
            setConnected(false);
          }}>Reboot into BLE</button></div>
          <div className="reboot"><button onClick={(e) => {
            e.preventDefault();
            rebootDevice(rebootMQTT);
            setOpenReboot(false);
            setConnected(false);
          }}>Reboot into MQTT</button></div>
          <div className="reboot cancel"><button onClick={(e) => {
            e.preventDefault();
            setOpenReboot(false);
          }}>Cancel</button></div>
        </div>
      </div>
    </div >
  );
}