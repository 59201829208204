const { v4: uuidv4 } = require('uuid');

// List of available payload options for executing pre-configured code
export const options = [{
  payload: {
    "id": uuidv4(),
    "v": {
      "lan.static_ip": 1,
      "lan.ip_config.ip": "10.1.10.201",
      "lan.ip_config.gw": "10.1.10.1",
      "lan.ip_config.mask": "255.255.255.0",
      "mqtt.broker.address": "10.1.10.173",
      "mqtt.broker.port": 1884,
      "mqtt.broker.user": "",
      "mqtt.broker.pass": ""
    }
  },
  description: "Set Static IP",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "lan.static_ip": 0
    }
  },
  description: "Set DHCP",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s2.calibrate": "(round((v - 25) / 2400, 2), False)",
      "sensor.s2.calibrated_range": [0.0, 0.0, 10.0],
      "sensor.s2.convert": `(float('{:.2f}'.format(v)), 'V', False)`,
      "sensor.s2.deadband": 100,
      "sensor.s3.calibrate": "(v, False)",
      "sensor.s3.calibrated_range": [0.0, 0.0, 0.0],
      "sensor.s3.convert": `(round(v/32767, 2), '%', False)`,
      "sensor.s3.deadband": 100,
      "main.loop_period_ms": 10
    }
  },
  description: "Reset SHARC to Default Settings",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "device.reset": true
    }
  },
  description: "Reset SHARC",
  destination: "cmd/action"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "cfg.save": true
    }
  },
  description: "Save Settings",
  destination: "cmd/action"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "ud.set": { "job_number": 123456 }
    }
  },
  description: "Set User Settings",
  destination: "cmd/action"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "mqtt.broker.address": "137.184.193.22",
      "mqtt.broker.port": 1883,
      "mqtt.broker.user": "",
      "mqtt.broker.pass": ""
    }
  },
  description: "Digital Ocean Broker",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "mqtt.broker.address": "10.1.10.173",
      "mqtt.broker.port": 1884,
      "mqtt.broker.user": "",
      "mqtt.broker.pass": ""
    }
  },
  description: "Change Broker Settings",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s0.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='any')`,
      "sensor.s0.convert": `(v, 'count', False)`,
      "sensor.s1.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='any')`,
      "sensor.s1.convert": `(v, 'count', False)`,
      "main.loop_period_ms": 10,
      "di.irq": false
    }
  },
  description: "Any Edge Counter",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s0.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='falling')`,
      "sensor.s0.convert": `(v, 'count', False)`,
      "sensor.s1.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='falling')`,
      "sensor.s1.convert": `(v, 'count', False)`,
      "main.loop_period_ms": 10,
      "di.irq": false
    }
  },
  description: "Falling Edge Counter",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s0.calibrate": `uc.call('on_sensor_calibrate__di_irq_accumulator', sensor=ctx, value=v, default=(v, True))`,
      "sensor.s0.convert": `(v, 'count', False)`,
      "sensor.s1.calibrate": `uc.call('on_sensor_calibrate__di_irq_accumulator', sensor=ctx, value=v, default=(v, True))`,
      "sensor.s1.convert": `(v, 'count', False)`,
      "main.loop_period_ms": 500,
      "di.irq": true
    }
  },
  description: "IRQ Accumulator",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s0.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='rising')`,
      "sensor.s0.convert": `(v, 'count', False)`,
      "sensor.s1.calibrate": `uc.call('on_sensor_calibrate__di_edge_counter', sensor=ctx, value=v, default=(v, False), edge='rising')`,
      "sensor.s1.convert": `(v, 'count', False)`,
      "main.loop_period_ms": 10,
      "di.irq": false
    }
  },
  description: "Rising Edge Counter",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "sensor.aggregate": true,
      "sensor.discrete": false,
      "sensor.calibrate": true,
      "sensor.convert": true,
      "sensor.s0.calibrate": `(v, False)`,
      "sensor.s0.convert": `(float('{:.1f}'.format(v)), '/', False)`,
      "sensor.s1.calibrate": `(v, False)`,
      "sensor.s1.convert": `(float('{:.1f}'.format(v)), '/', False)`,
      "main.loop_period_ms": 10,
      "di.irq": false
    }
  },
  description: "On/Off",
  destination: "cmd/cfg"
},
{
  payload: {
    "id": uuidv4(),
    "v": {
      "ud.set": "counters.reset"
    }
  },
  description: "Reset Counter",
  destination: "cmd/cfg"
},

]