
const s0 = '00000001-0000-1337-1337-000000000005'
const s0Units = '00000005-0000-1337-1337-000000000005'
const s0Ts = '00000009-0000-1337-1337-000000000005'

const s1 = '00000002-0000-1337-1337-000000000005'
const s1Units = '00000006-0000-1337-1337-000000000005'
const s1Ts = '0000000a-0000-1337-1337-000000000005'

const voltage = '00000003-0000-1337-1337-000000000005'
const voltageUnits = '00000007-0000-1337-1337-000000000005'
const voltageTs = '0000000b-0000-1337-1337-000000000005'

const current = '00000004-0000-1337-1337-000000000005'
const currentUnits = '00000008-0000-1337-1337-000000000005'
const currentTs = '0000000c-0000-1337-1337-000000000005'


// This is used to display the buttons for sensor selection
export default function Sensors({ setSelectedChart, selectedChart, setSensorData, getData, setCurrentSensor }) {
  return (
    <div id="sensors">

      <button style={{ backgroundColor: selectedChart === 'd-pnp' ? "#0195f1" : "#e4e7eb", color: selectedChart === 'd-pnp' ? "white" : "black" }} onClick={(e) => {
        e.preventDefault()
        setSelectedChart('d-pnp')
        getData(s0, s0Units, s0Ts)
        setCurrentSensor('s0')
      }} className="sensor-buttons digital-npn">Digital PNP</button>
      <button style={{ backgroundColor: selectedChart === 'd-npn' ? '#0195f1' : '#e4e7eb', color: selectedChart === 'd-npn' ? "white" : "black" }} onClick={(e) => {
        e.preventDefault()
        setSelectedChart('d-npn')
        getData(s1, s1Units, s1Ts)
        setCurrentSensor('s1')
      }} className="sensor-buttons digital-npn">Digital NPN</button>
      <button style={{ backgroundColor: selectedChart === 'a-010' ? '#0195f1' : '#e4e7eb', color: selectedChart === 'a-010' ? "white" : "black" }} onClick={(e) => {
        e.preventDefault()
        setSelectedChart('a-010')
        getData(voltage, voltageUnits, voltageTs)
        setCurrentSensor('voltage')
      }} className="sensor-buttons analog010">Analog 0-10v</button>
      <button style={{ backgroundColor: selectedChart === 'a-420' ? '#0195f1' : '#e4e7eb', color: selectedChart === 'a-420' ? "white" : "black" }} onClick={(e) => {
        e.preventDefault()
        setSelectedChart('a-420')
        getData(current, currentUnits, currentTs)
        setCurrentSensor('current')
      }} className="sensor-buttons analog420">Analog 4-20ma</button>
    </div>
  )
}